import React, { useEffect, useRef, useState } from 'react'
import LandingMenu from '../landing/LandingMenu'
import WorkspaceInfoIcon from '../../assets/WorkspaceInfo.svg'
import CreditCard from '../../assets/CreditCard.svg'
import Users from '../../assets/Users.svg'
import '../../css/workspaceInfo.css'
import ImageSelector from '../shapes/ImageSelector'
import axios from 'axios'
import Alert from '../shapes/Alert'
import { useNavigate, useParams } from 'react-router-dom';
import { url } from 'inspector'
import { json } from 'node:stream/consumers'
import Logout from '../auth/Logout'
import UserCircle from '../auth/UserCircle'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import WorkspaceIconPanel from './WorkspaceIconPanel'
import { Workspace } from '../../types/Workspace'
import { useDispatch } from 'react-redux'
import { setSelectedWorkspace } from '../../features/WorkspaceSlice'
const token = localStorage.getItem('token');

const user = localStorage.getItem('user');
const userId = user ? JSON.parse(user).id : '';
const userName = user ? JSON.parse(user).name : '';
const UserShortName = userName ? userName.substring(0, 2).toUpperCase() : '';

const WorkspaceInfo = () => {
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const dispatch = useDispatch<AppDispatch>();
  const { uniCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [snippet, setSnippet] = useState('');
  const [legalName, setLegalName] = useState('');
  const [description, setDescription] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [workspaceId, setWorkspaceId] = useState<number>();
  const [imagePath, setImagePath] = useState<string>();
  const [imageChanged, setImageChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>({
  //   id: -1,
  //   name: '',
  //   snippet: '',
  //   description: '',
  //   uniCode: '',
  //   image: '',
  // });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const pages = {
    'Workspace Info': [`/workspace-info/${uniCode}`, WorkspaceInfoIcon],
    'Collaborator': ['/collaborator/' + uniCode, Users],
    'Plan and Billing': ['', CreditCard],
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const workspaceName = document.querySelector('input[name="name"]') as HTMLInputElement;
    const snippet = document.querySelector('input[name="snippet"]') as HTMLInputElement;
    const description = document.querySelector('textarea[name="description"]') as HTMLInputElement;
    const submitButton = document.querySelector('input[type="submit"]') as HTMLInputElement;
    const { name, value } = e.target;

    // check if the target is a textarea to set max content length 
    if (e.currentTarget.name === 'name') {
      if (value.length > 10) {
        e.currentTarget.value = value.slice(0, 10);
        return;
      }
    }
    if (e.currentTarget.name === 'snippet') {
      if (value.length > 50) {
        e.currentTarget.value = value.slice(0, 50);
        return;
      }
    }
    if (e.currentTarget.name === 'legalName') {
      if (value.length > 50) {
        e.currentTarget.value = value.slice(0, 50);
        return;
      }
    }
    if (e.currentTarget.tagName === 'TEXTAREA') {
      if (value.length > 250) {
        e.currentTarget.value = value.slice(0, 250);
        return;
      }
    }
    if (name === 'name') {
      setWorkspaceName(value);
    } else if (name === 'snippet') {
      setSnippet(value);
    } else if (name === 'legalName') {
      setLegalName(value);
    } else if (name === 'description') {
      setDescription(value);
    }
    if (workspaceName.value && snippet.value && description.value) {
      submitButton.classList.remove('inactive');
    } else {
      submitButton.classList.add('inactive');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // const workspaceName = document.querySelector('input[name="name"]') as HTMLInputElement;
    // const snippet = document.querySelector('input[name="snippet"]') as HTMLInputElement;
    // const description = document.querySelector('input[name="description"]') as HTMLInputElement;
    const formData = new FormData();

    formData.append('name', workspaceName);
    formData.append('snippet', snippet);
    formData.append('legal_name', legalName);
    formData.append('description', description);

    if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0]) {
      formData.append('image', fileInputRef.current.files[0]);
    }

    // saveWorkspace(token, formData);
    try {
      const savedWorkspace = await saveWorkspace(token, formData);
      if (savedWorkspace) {
        setWorkspaceId(savedWorkspace.id);
        navigate('/');
      }
    } catch (error) {
      console.error('Error saving workspace:', error);
    }
  };

  const saveWorkspace = async (token: string | null, data: FormData) => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/save-workspace`;
      data.append('user_id', JSON.parse(localStorage.getItem('user') || '{}').id);
      if (uniCode) {
        url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspace/${uniCode}`;
      }
      setLoading(true)
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShowAlert(true)
      setAlertBody('Workspace saved successfully');
      setAlertStatus('success');
      setWorkspaceId(response.data.id);
      // localStorage.setItem('selectedWorkspace', JSON.stringify(response.data.selectedWorkspace));
      dispatch(setSelectedWorkspace(response.data.selectedWorkspace));
      return response.data;

    } catch (error: any) {
      console.error('Error saving workspace:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      setAlertBody('Workspace did not saved successfully');
      setAlertStatus('error');
      setShowAlert(true)
      throw error;
    } finally {
      setTimeout(() => {
        setShowAlert(false)
      }, 5000);
      setLoading(false)
      // const submitButton = document.querySelector('input[type="submit"]') as HTMLInputElement;
      // submitButton.classList.add('inactive');
    }
  };

  const fetchInitialData = async (token: string | null, uniCode: string) => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspace/${uniCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWorkspaceName(response.data.name);
      setSnippet(response.data.snippet);
      setDescription(response.data.description);
      // setSelectedWorkspace(response.data);

      if (response.data.image) {
        setImagePath(`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${response.data.image}`);
      }
    } catch (error) {
      console.error('Error fetching workspace:', error);
    } finally {
      setLoading(false);
    }
  }
  // useEffect(() => {
  //   console.log("workspaceInfo" + selectedWorkspace);
  // }, [selectedWorkspace]);
  const handleImageChange = (newImageUrl: string) => {
    setImageChanged(true); // Set imageChanged to true when image is changed
  };

  const handleShowAccountPopup = () => {
    const accountPopup = document.getElementById('account-popup') as HTMLElement;
    if (accountPopup) {
      if (accountPopup.classList.contains('overnight')) {
        accountPopup.classList.remove('overnight');
      } else {
        accountPopup.classList.add('overnight');
      }
    }
  }
  useEffect(() => {
    if (workspaceId) {
      navigate(`/workspace-info/${workspaceId}`);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (uniCode) {
      fetchInitialData(token, uniCode);
    } else {
      console.log('NO UNICODE');
    }
  }, [uniCode]);

  // useEffect(() => {
  //   console.log("workspaceInfo: selectedWorkspace: ", selectedWorkspace);
  // }, [selectedWorkspace]);
  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <LandingMenu pages={pages} hidePopup={true} hidePanelContents={false} pageProp="WORKSPACE INFO" selectedWorkspace={selectedWorkspace} />
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Workspace info</h3>
            <p>Configure your workspace settings</p>
          </div>
          <UserCircle />
        </div>

        <div id='content-container' className="">
          {
            <>
              <p className={`bold-text ${loading ? '' : 'overnight'}`} style={
                {
                  'color': '#94ddf3',
                  'display': 'block',
                  'fontSize': '2rem',
                  'position': 'absolute',
                  'marginBottom': '.5rem'
                }}>
                Loading...
              </p>
            </>
          }
          {
            uniCode
              ?
              <WorkspaceIconPanel page="workspace" uniCode={uniCode} />
              :
              ''
          }
          <div className="form-box bg-white rounded-radius w-50 m-t-5 pd-1 right-panel">
            {/* <p>Workspace name</p> */}
            <form id='workspace-form' method="post" onSubmit={handleSubmit}>
              <input type="text" onChange={handleChange} value={workspaceName} name='name' placeholder='WORKSPACE NAME' className='workspace-input rounded-radius' />
              <input type="text" onChange={handleChange} value={snippet} name='snippet' placeholder='SNIPPET' className='workspace-input rounded-radius' />
              <input type="text" onChange={handleChange} value={legalName} name='legalName' placeholder='LEGALNAME' className='workspace-input rounded-radius' />
              {/* <input type="text" onChange={handleChange} value={description} name='description' placeholder='COMPANY DESCRIPTION' className='workspace-input rounded-radius' /> */}
              <textarea onChange={handleChange} value={description} name='description' placeholder='COMPANY DESCRIPTION' className='workspace-input rounded-radius' rows={3} style={{ 'height': '10rem' }}></textarea>
              <ImageSelector
                initialImageUrl={imagePath || 'https://via.placeholder.com/150'}
                onImageChange={handleImageChange}
                fileInputRef={fileInputRef} />
              <input type="submit" value="Save" className={`${imageChanged ? '' : 'inactive'} submit-button bg-black text-white rounded-radius m-t-1`} />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceInfo