import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Alert from '../shapes/Alert';
import Expand from './../../assets/Expand4.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { Client } from '../../types/Client';
import { Link } from 'react-router-dom';
import '../../css/clients.css'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientPopup from './ClientPopup';
import { ClientAddress } from '../../types/ClientAddress';
import NavigationTabs from '../layouts/NavigationTabs';

const Invoices = () => {
  const param = useParams<{ uniCode: string }>();
  const uniCode = param.uniCode;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client | null>({
    id: 0,
    name: '',
    code: '',
    phone: '',
    email: '',
    vatTreatment: '',
    trn: '',
    entitySchemeId: '',
    registrationNumber: '',
    customerCode: '',
    mode: '',
    arabicName: '',
    contactPersonName: '',
    contactPersonMobile: '',
    preferredChannel: '',
    ccEmail: '',
    clientAddress: {
      id: 0,
      city: '',
      district: '',
      streetAddress: '',
      buildingNumber: '',
      additionalNumber: '',
      postalCode: '',
      country: '',
      countryIsoCode: '',
    },
  });
  const [clientAddress, setClientAddress] = useState<ClientAddress | null>({
    id: 0,
    city: '',
    district: '',
    streetAddress: '',
    buildingNumber: '',
    additionalNumber: '',
    postalCode: '',
    country: '',
    countryIsoCode: '',
  });
  const [showInvoiceExpandPopup, setShowInvoiceExpandPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleClientsChange = (clients) => {
    setClients(clients)
  }
  const fetchInitialData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-all-clients/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      setClients(response.data.clients)
      console.log(response.data.clients);
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching invoices:', error);
    }
  }
  useEffect(() => {
    if (counter < 1)
      fetchInitialData();
    setCounter(counter + 1);
  }, []);

  const handleClientInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (client) {
      const updatedName = name === 'client_name' ? 'name' : name; // Map input field name to client field
      setClient((prevClient) => (prevClient ? { ...prevClient, [updatedName]: value } : null));
    }
  };

  useEffect(() => {
    if (client) {
      console.log('useEffect Client:', client);
    }
  }, [client]);

  const handleClientAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (clientAddress) {
      // Map input name from snake_case to camelCase
      const updatedName = name === 'street_address' ? 'streetAddress'
        : name === 'building_number' ? 'buildingNumber'
          : name === 'additional_number' ? 'additionalNumber'
            : name === 'postal_code' ? 'postalCode'
              : name; // Default to the input's name

      setClientAddress((prevAddress) => (prevAddress ? { ...prevAddress, [updatedName]: value } : null));
    }
  };

  const handleCountryChange = (newValue: string) => {
    setClientAddress((prevAddress) =>
      prevAddress ? { ...prevAddress, country: newValue } : null
    );
  };

  const handleShowClientPopup = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const target = e.currentTarget;
    // empty client state
    setClient(
      {
        id: 0,
        name: '',
        code: '',
        phone: '',
        email: '',
        vatTreatment: '',
        trn: '',
        entitySchemeId: '',
        registrationNumber: '',
        customerCode: '',
        mode: '',
        arabicName: '',
        contactPersonName: '',
        contactPersonMobile: '',
        preferredChannel: '',
        ccEmail: '',
        clientAddress: {
          id: 0,
          city: '',
          district: '',
          streetAddress: '',
          buildingNumber: '',
          additionalNumber: '',
          postalCode: '',
          country: '',
          countryIsoCode: '',
        },
      }
    );
    setClientAddress(
      {
        id: 0,
        city: '',
        district: '',
        streetAddress: '',
        buildingNumber: '',
        additionalNumber: '',
        postalCode: '',
        country: '',
        countryIsoCode: '',
      }
    );
    if (target.tagName === 'TR') {
      fetchClientData(target.getAttribute('data-id'));
      const unicode = target.getAttribute('data-unicode');
      console.log('unicode:', unicode);

    }
    const clientPopup = document.getElementById('client-popup') as HTMLElement;
    if (clientPopup) {
      clientPopup.classList.add('show'); // Add the 'show' class to slide in the popup
    }
  }

  const fetchClientData = async (id: string | null) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-client/${id}`;
    try {
      setIsLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });

      const client = response.data.client;
      const clientAddress = response.data.clientAddress;

      setClient(client);
      setClientAddress(clientAddress);

      console.log('Client data:', client);
      console.log('client address:', clientAddress);
    } catch (error) {
      console.log('Error in fetching client data:', error);
    } finally {
      setIsLoading(false);
    }
  }
  const tabs = [
    { label: 'INFO SHEET', tabSelector: 'INFO SHEET', url: '/info-sheet', status: 'notClickable' },
    { label: 'INVOICE SHEET', tabSelector: 'INVOICE SHEET', url: '/invoice-sheet', status: 'notClickable' },
    { label: 'ACCOUNT STATEMENT', tabSelector: 'ACCOUNT STATEMENT', url: '/accountant-statement', status: 'notClickable' },
  ];

  return (
    <>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <NavigationTabs tabs={tabs} />
      <div className='title-container'>
        <h2><b>Contacts</b></h2>
      </div>
      <table id='clients-table' className='w-full'>
        <thead className='bg-grassy'>
          <tr>
            <th className='table-cell pl-6'>code</th>
            <th className='table-cell'>CUSTOMER</th>
            <th className='table-cell'>email</th>
            <th className='table-cell'>tax registration</th>
            <th className='table-cell'>country</th>
            <th className='table-cell'></th>
          </tr>
        </thead>
        <tbody>
          {
            clients.map((client, i) => {
              return (
                <tr key={client.id} className='client-tr pointer' data-unicode={1} onClick={handleShowClientPopup} data-id={client.id}>
                  <td className="code-td !pl-6">{client.code || '--'}</td>
                  <td>
                    {client.name}
                  </td>
                  {/* <td className="phone-td">{client.phone || '--'}</td> */}
                  <td>
                    {client.email || '--'}
                  </td>
                  <td className="tax-registration-td">{client.trn || '--'}</td>
                  <td className="country-id-td">{client.clientAddress ? client.clientAddress.country : '--'}</td>
                  <td className="country-id-td">
                    <FontAwesomeIcon icon={faPaperclip} size="2x" color="#000" />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
        }}>
        Loading...
      </p> : ""}
      {/* <Link className='create-client create-button clickable' to={`/create-invoice/${uniCode}`}>Add Client</Link> */}
      <div className="add-client create-button clickable pointer" onClick={handleShowClientPopup}>Add Client</div>
      {/* <InvoiceExpandPopup showInvoiceExpandPopup={showInvoiceExpandPopup} /> */}
      <ClientPopup
        client={client}
        clientAddress={clientAddress}
        handleClientInputChange={handleClientInputChange}
        handleClientAddressInputChange={handleClientAddressInputChange}
        handleCountryChange={handleCountryChange}
        handleClientsChange={handleClientsChange}
      />
    </>
  )
}

export default React.memo(Invoices)