import React from 'react'
import { Link } from 'react-router-dom'

interface OptionProps {
  link: string;
  label: string;
}

interface CreateOptionsProps {
  options: OptionProps[];
}

const CreateOptions: React.FC<CreateOptionsProps> = ({ options }) => {
  return (
    <div id="invoice-types" className="create-container overnight">
      {options.map((option, index) => (
        <Link
          key={index}
          to={option.link}
          className={`unselectable pointer create-pile ${index === 1 ? 'min-w-[14rem]' : ''}`}
        >
          {option.label}
        </Link>
      ))}
    </div>
  );
};

export default CreateOptions;
