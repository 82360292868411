// BanksRedirect.tsx
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const BanksRedirect: React.FC = () => {
    const { uniCode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchBankIdentifier() {
            if (!uniCode) return;

            try {
                const token = localStorage.getItem('token');
                const response = await axios.get<{ bankIdentifier: string }>(
                    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-identifier?uniCode=${uniCode}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const { bankIdentifier } = response.data;

                navigate(`/banks/${uniCode}/${bankIdentifier}`, { replace: true });
            } catch (error) {
                console.error('Failed to fetch bank identifier', error);
            }
        }

        fetchBankIdentifier();
    }, [uniCode, navigate]);

    return <div>Loading...</div>;
};

export default BanksRedirect;
