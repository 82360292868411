import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface File {
  id: number;
  name: string;
  path: string;
  mime_type: string;
}

interface FileDisplayProps {
  itineraryId: number;
  endpoint: string;
  deleteEndpoint: string;
}

const FileDisplay: React.FC<FileDisplayProps> = ({ itineraryId, endpoint, deleteEndpoint }) => {
  const token = localStorage.getItem('token');
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail/${itineraryId}/${endpoint}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFiles(response.data);
        const resizeWrapper = document.querySelector('.resize-wrapper') as HTMLDivElement;
        if (resizeWrapper) {
          resizeWrapper.style.minWidth = '70px';
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    fetchFiles();
  }, [itineraryId]);

  const deleteFile = async (fileId: number) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail/${itineraryId}/${deleteEndpoint}/${fileId}`;
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(`File ${fileId} deleted from database`);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handlePreviewClick = (file: File) => {
    const fileDownloadUrl = `${process.env.REACT_APP_API_URL}/download-file/${file.name}`;
    const previewWindow = window.open('', '_blank');
    if (previewWindow) {
      previewWindow.document.write(`
        <html>
          <head><title>${file.name}</title></head>
          <body style="display: flex; align-items: center; gap: 20px; margin-top: 50px;">
          FileDisplay
            ${file.mime_type.startsWith('image/')
          ? `<img src="${file.path}" alt="${file.name}" style="max-width: 80%; max-height: 80%;" />`
          : `<embed src="${file.path}" type="${file.mime_type}" style="width: 80%; height: 80%;" />`
        }
            <div style="display: flex; gap: 10px;">
              <a href="${fileDownloadUrl}" download="${file.name}" 
                style="font-size: 16px; text-decoration: none; color: #4A4A4A; background: #D9FFD2;
                  padding: 1rem;
                  border-radius: 1rem;">
                Download
              </a>
              <button
                onclick="window.opener.postMessage({ action: 'deleteFile', fileId: ${file.id} }, '*'); window.close();"
                style="font-size: 16px; color: white; background: #ff4d4f;
                  padding: 1rem; border: none; border-radius: 1rem; cursor: pointer;">
                Delete
              </button>
            </div>
          </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };


  // Listen for messages from the preview window s
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.action === 'deleteFile') {
        deleteFile(event.data.fileId);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const renderFiles = () => {
    if (files.length === 0) {
      return <div></div>;
    } else {
      return files.map((file, index) => (
        <div
          key={`${file.id}-${index}`}
          style={{ display: 'flex', alignItems: 'center', gap: '2px', marginBottom: '10px' }}
        >
          {file.mime_type.startsWith('image/') ? (
            <img
              src={file.path}
              alt="Preview"
              className='min-w-[20px] w-[20px] min-h-[30px] h-[30px] cursor-pointer mx-1'
              // style={{ minWidth: '20px', width: '20px', minHeight: '30px', height: '30px', cursor: 'pointer',  }}
              onClick={() => handlePreviewClick(file)}
            />
          ) : (
            <span onClick={() => handlePreviewClick(file)} style={{ cursor: 'pointer' }}>
              📄 PDF
            </span>
          )}
        </div>
      ));
    }
  };

  return (
    <div className='file-display' style={{ display: 'flex', flexDirection: 'row', gap: '2px', flexWrap: 'wrap' }}>
      {renderFiles()}
    </div>
  );
};

export default FileDisplay;
