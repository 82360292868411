import React, { useState } from 'react';
import { TableCell, Popover, Typography } from '@mui/material';

const TransactionCell: React.FC<{ transaction: any }> = ({ transaction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transaction-popover' : undefined;

  return (
    <>
      {/* The clickable cell */}
      <TableCell onClick={handleClick} style={{ cursor: 'pointer' }}>
        {transaction.transaction_reference}
      </TableCell>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography style={{ padding: '8px', maxWidth: '300px' }}>
          {transaction.statement_reference || 'No statement reference available.'}
        </Typography>
      </Popover>
    </>
  );
};

export default TransactionCell;
