export const addCommaAndSeparator = (num: number | string) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const addCommaToWholeNumber = (num: number | string) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return Math.round(num).toString().replace(/\d(?=(\d{3})+$)/g, '$&,');
}

export const getCurrentMonthName = (): string => {
  const date = new Date();
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[date.getMonth()];
};

export const removeSeparator = (num: string) => {
  return num.replace(/,/g, '');
}

export const removeDecimalPoint = (num: string) => {
  return num.replace(/\.00/g, '');
}

export const getCurrentDate = () => {
  const date = new Date();
  return date.toISOString().split('T')[0];
}

export const getMonthAbbreviation = (date: string) => {
  const month = new Date(date).getMonth();
  const monthAbbreviation = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthAbbreviation[month].toUpperCase();
}

export const getDay = (date: string) => {
  return new Date(date).getDate();
}

export const getYear = (date: string) => {
  return new Date(date).getFullYear();
}

export const preventCharInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];
  const regex = /^[0-9.,]$/; // Allow numbers, comma, and point

  if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};


export const withSeparator = (input: number) => {
  return input ?Math.round(input).toLocaleString('en-US') : '';
}