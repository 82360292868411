import axios from 'axios';
import { update } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

interface UploadedFile {
  id: number; // ID is optional since it is assigned after upload
  name: string;
  type: string;
  originalFile: File; // Store the original File object here
}

interface FileUploaderProps {
  itineraryId: number;
  endpoint: string;
  updateFile: (args: { fileNumber: number; fileName: string }) => void;
  source: string;
  fileUPloaderTdRef: React.RefObject<HTMLTableCellElement>;
}

const FileUploader: React.FC<FileUploaderProps> = ({ itineraryId, endpoint, updateFile, source, fileUPloaderTdRef }) => {
  const token = localStorage.getItem('token');
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []).map((file) => ({
      name: file.name,
      type: file.type,
      originalFile: file,
    })) as UploadedFile[];

    if (selectedFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

      for (const file of selectedFiles) {
        const fileId = await uploadFileToServer(file.originalFile);
        if (fileId) {
          // Update the state with the file ID after upload
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.name === file.name ? { ...f, id: fileId } : f
            )
          );
        }
      }
    }
  };

  const handleIconClick = () => {
    fileInputRef.current?.click();
  };

  const uploadFileToServer = async (file: File): Promise<number | null> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('itinerary_id', itineraryId.toString());

    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${endpoint}`;
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log("data from uploadFileToServer");
      console.log(data);
      // updateNumberOfPop(data.numberOfPop);
      updateFile(
        {
          'fileNumber': data.numberOfFiles,
          'fileName': source
        }
      )
      // create array with index name and number
      setUploadedFile(data.file);
      console.log("data");
      if (response.ok && data.fileId) {
        return data.fileId;
      } else {
        console.error('Failed to upload file:', file.name);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    return null;
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.action === 'deletePreviewFile') {
        console.clear();
        console.log(event.data);
        handleDeleteFile(event.data.fileId);
        event.data.fileId = null;
        console.log(event.data);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    // console.log("uploadedFile from useEffect");
    // console.log(uploadedFile);
  }, [uploadedFile]);

  useEffect(() => {
    // console.log("files from useEffect");
    // console.log(files);
    // console.log(uploadedFile?.id);
  }, [files, uploadedFile]);


  const handlePreviewClick = (file: UploadedFile) => {
    const fileURL = URL.createObjectURL(file.originalFile);
    const previewWindow = window.open('', '_blank');
    if (previewWindow) {
      previewWindow.document.write(`
        <html>
          <head><title>File Preview</title></head>
          <body style="display: flex; flex-direction: column; align-items: center; gap: 20px; margin-top: 50px;">
            ${file.type.startsWith('image/')
          ? `<img src="${fileURL}" alt="Preview" style="max-width: 80%; max-height: 80%;" data-id='${uploadedFile ? uploadedFile.id : ''}' />`
          : `<embed src="${fileURL}" type="application/pdf" style="width: 80%; height: 80%;" />`
        }
            <div style="display: flex; gap: 10px; margin-top: 20px;">
              <a href="${fileURL}" download="${file.name}" 
                style="font-size: 16px; text-decoration: none; color: #4A4A4A; background: #D9FFD2;
                  padding: 1rem;
                  border-radius: 1rem;">
                Download
              </a>
              <button
                onclick="window.opener.postMessage({ action: 'deletePreviewFile', fileId: ${uploadedFile ? uploadedFile.id : ''} }, '*'); window.close();"
                style="font-size: 16px; color: white; background: #ff4d4f;
                  padding: 1rem; border: none; border-radius: 1rem; cursor: pointer;">
                Delete
              </button>
            </div>
          </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };

  const handleDeleteFile = async (fileId: number) => {
    console.log('handleDeleteFile');
    console.log(fileId);
    // if (!file.id) return;

    console.log('handleDeleteFile');
    console.log(uploadedFile);
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail/${itineraryId}/files/${fileId}`;
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const uploadedImage = document.querySelector(`img[data-id="${fileId}"]`);
      if (uploadedImage) {
        uploadedImage.remove();
        setUploadedFile(undefined);
      }

      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // const renderPreview = () => {
  //   if (files.length === 0) {
  //     return <div></div>;
  //   } else {
  //     return files.map((file, index) => {
  //       const fileURL = URL.createObjectURL(file.originalFile);
  //       return (
  //         <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
  //           {file.type.startsWith('image/') ? (
  //             <img
  //               src={fileURL}
  //               data-id={uploadedFile ? uploadedFile.id : ''}
  //               alt="Preview123"
  //               className={uploadedFile ? '' : 'pointer-events-none blur-sm'}
  //               style={{ width: '20px', height: '30px', cursor: 'pointer' }}
  //               onClick={() => handlePreviewClick(file)}
  //             />
  //           ) : (
  //             <span onClick={() => handlePreviewClick(file)} style={{ cursor: 'pointer' }}>
  //               📄 PDF
  //             </span>
  //           )}
  //         </div>
  //       );
  //     });
  //   }
  // };

  const renderPreview = () => {
    if (files.length === 0) return;

    const targetDiv = document.querySelector(`td[data-id='${source}-${itineraryId}']`)?.querySelector('.file-display') as HTMLDivElement; // Target div where the image will be inserted

    if (fileUPloaderTdRef.current) {
    }
    if (targetDiv) {
      // Clear the target div before inserting new images
      // targetDiv.innerHTML = ;

      files.forEach((file, index) => {
        const fileURL = URL.createObjectURL(file.originalFile);

        if (file.type.startsWith('image/')) {
          // Create an image element
          const imgElement = document.createElement('img');
          imgElement.src = fileURL;
          imgElement.alt = `Preview ${index}`;
          imgElement.dataset.id = uploadedFile ? uploadedFile.id?.toString() || '' : '';
          imgElement.style.width = '20px';
          imgElement.style.height = '30px';
          imgElement.style.cursor = 'pointer';
          imgElement.onclick = () => handlePreviewClick(file); // Attach click handler

          // Append the image to the target div
          targetDiv.appendChild(imgElement);
        } else {
          // Create a span for non-image files (like PDFs)
          const spanElement = document.createElement('span');
          spanElement.textContent = '📄 PDF';
          spanElement.style.cursor = 'pointer';
          spanElement.onclick = () => handlePreviewClick(file);

          // Append the span to the target div
          targetDiv.appendChild(spanElement);
        }
      });
    }
  };

  useEffect(() => {
    renderPreview(); // Render the preview when files change
  }, [files]);


  return (
    <div style={{ display: 'inline-flex', flexDirection: 'column', gap: '2px', float: 'left' }}>
      <input
        type="file"
        accept="image/*,.pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        multiple
      />
      <span onClick={handleIconClick} style={{ cursor: 'pointer', fontSize: '20px' }}>
        <FontAwesomeIcon icon={faPaperclip} style={{ height: '18px', width: '11px' }} color="#000" />
      </span>
      <div className="aim">
        {/* {renderPreview()} */}

      </div>
    </div>
  );
};

export default FileUploader;
