import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Alert from '../shapes/Alert';
import Expand from './../../assets/Expand4.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { Vendor } from '../../types/Vendor';
import { Link } from 'react-router-dom';
import '../../css/vendors.css'
import { faExpandArrowsAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VendorPopup from './VendorPopup';
import { VendorAddress } from '../../types/VendorAddress';

const Vendors = () => {
  const param = useParams<{ uniCode: string }>();
  const uniCode = param.uniCode;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisibleRowId, setMenuVisibleRowId] = useState<number | null>(null); // Track the row ID for the context menu

  const [vendor, setVendor] = useState<Vendor | null>({
    id: 0,
    name: '',
    code: '',
    phone: '',
    email: '',
    vatTreatment: '',
    trn: '',
    iban: '',
    swift: '',
    vendorAddress: {
      id: 0,
      city: '',
      district: '',
      streetAddress: '',
      buildingNumber: '',
      additionalNumber: '',
      postalCode: '',
      country: '',
    },
  });
  const [vendorAddress, setVendorAddress] = useState<VendorAddress | null>({
    id: 0,
    city: '',
    district: '',
    streetAddress: '',
    buildingNumber: '',
    additionalNumber: '',
    postalCode: '',
    country: '',
  });
  const [showInvoiceExpandPopup, setShowInvoiceExpandPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleVendorsChange = (vendors) => {
    setVendors(vendors)
  }
  const fetchInitialData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-all-vendors/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      setVendors(response.data.vendors)
      console.log(response.data.vendors);
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching invoices:', error);
    }
  }
  useEffect(() => {
    if (counter < 1)
      fetchInitialData();
    setCounter(counter + 1);
  }, []);

  const handleVendorInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.clear();
    console.log(name + " " + value);
    if (vendor) {
      const updatedName = name === 'vendor_name' ? 'name' : name; // Map input field name to vendor field
      setVendor((prevVendor) => (prevVendor ? { ...prevVendor, [updatedName]: value } : null));
    }
  };

  useEffect(() => {
    if (vendor) {
      console.log('useEffect Vendor:', vendor);
    }
  }, [vendor]);

  const handleVendorAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (vendorAddress) {
      // Map input name from snake_case to camelCase
      const updatedName = name === 'street_address' ? 'streetAddress'
        : name === 'building_number' ? 'buildingNumber'
          : name === 'additional_number' ? 'additionalNumber'
            : name === 'postal_code' ? 'postalCode'
              : name; // Default to the input's name

      setVendorAddress((prevAddress) => (prevAddress ? { ...prevAddress, [updatedName]: value } : null));
    }
  };

  const handleCountryChange = (newValue: string) => {
    setVendorAddress((prevAddress) =>
      prevAddress ? { ...prevAddress, country: newValue } : null
    );
  };

  const handleShowVendorPopup = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const target = e.currentTarget;
    // empty vendor state
    setVendor(
      {
        id: 0,
        name: '',
        code: '',
        phone: '',
        email: '',
        vatTreatment: '',
        trn: '',
        iban: '',
        swift: '',
        vendorAddress: {
          id: 0,
          city: '',
          district: '',
          streetAddress: '',
          buildingNumber: '',
          additionalNumber: '',
          postalCode: '',
          country: '',
        },
      }
    );
    setVendorAddress(
      {
        id: 0,
        city: '',
        district: '',
        streetAddress: '',
        buildingNumber: '',
        additionalNumber: '',
        postalCode: '',
        country: '',
      }
    );
    if (target.tagName === 'TR') {
      fetchVendorData(target.getAttribute('data-id'));
      const unicode = target.getAttribute('data-unicode');
      console.log('unicode:', unicode);

    }
    const vendorPopup = document.getElementById('vendor-popup') as HTMLElement;
    if (vendorPopup) {
      vendorPopup.classList.add('show'); // Add the 'show' class to slide in the popup
    }
  }

  const fetchVendorData = async (id: string | null) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-vendor/${id}`;
    try {
      setIsLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });

      const vendor = response.data.vendor;
      const vendorAddress = response.data.vendorAddress;

      setVendor(vendor);
      setVendorAddress(vendorAddress);

      console.log('Vendor data:', vendor);
      console.log('vendor address:', vendorAddress);
    } catch (error) {
      console.log('Error in fetching vendor data:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleContextMenu = (e: React.MouseEvent, rowId: number) => {
    e.preventDefault(); // Prevent the default browser context menu
    setMenuVisible(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisibleRowId(rowId); // Track which row is active
  };

  const handleOptionClick = (action: string, rowId: number) => {
    if (action.toLowerCase() === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to delete this vendor?');
      if (confirmDelete) {
        deleteVendor(rowId);
      }
    } else if (action.toLowerCase() === 'expand') {
      navigate(`/vendor/${rowId}`);
    }
    setMenuVisible(false); // Hide the menu after the action
    setMenuVisibleRowId(null); // Reset the active row
  };

  const deleteVendor = async (rowId: number) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/delete-vendor/${rowId}`;
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (vendors) {
        const updatedItineraries = vendors.filter((itinerary) => itinerary.id !== rowId);
        setVendors(updatedItineraries); // This should trigger a re-render
      }
    } catch (error) {
      console.error('Error deleting itinerary detail:', error);
    }
  };

  return (
    <>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <div className="navigate-tabs flex-container flex-start">
        <div className="tab clickable pointer active">INFO SHEET</div>
        <div className="tab clickable pointer">INVOICE SHEET</div>
        <div className="tab clickable pointer">ACCOUNT STATEMENT</div>
      </div>
      <div className='title-container'>
        <h2><b>Contacts</b></h2>
      </div>
      <table id='vendors-table' className='w-full'>
        <thead className='bg-grassy'>
          <tr>
            <th className='-cell w-[2rem]'></th>
            <th className='-cell pl-6'>name</th>
            <th className='-cell'>full name</th>
            <th className='-cell'>email</th>
            <th className='-cell'>tax registration</th>
            <th className='-cell'>country</th>
            <th className='-cell'>iban</th>
            <th className='-cell'>swift</th>
            <th className='-cell'></th>
          </tr>
        </thead>
        <tbody>
          {
            vendors.map((vendor, i) => {
              return (
                <tr key={vendor.id} className='vendor-tr pointer' data-unicode={1} onClick={handleShowVendorPopup} data-id={vendor.id}>
                  <td className="">
                    <FontAwesomeIcon icon={faExpandArrowsAlt} onContextMenu={(e) => handleContextMenu(e, vendor.id)} />
                    {menuVisible && menuVisibleRowId === vendor.id && (
                      <ul className='context-menu' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
                        <li className="border-b border-gray-200 hover:bg-gray-100" style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={() => handleOptionClick('Delete', vendor.id)}
                        >Delete</li>
                        <li className="border-b border-gray-200 hover:bg-gray-100" style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={() => handleOptionClick('Expand', vendor.id)}
                        >Expand</li>
                      </ul>
                    )}
                  </td>
                  <td className="code-td pl-6">{vendor.code || '--'}</td>
                  <td>
                    {vendor.name}
                  </td>
                  <td>
                    {vendor.email || '--'}
                  </td>
                  <td className="tax-registration-td">{vendor.trn || '--'}</td>
                  <td className="country-id-td">{vendor.vendorAddress ? vendor.vendorAddress.country : '--'}</td>
                  <td className="iban-td">{vendor.iban || '-'}</td>
                  <td className="swift-td">{vendor.swift || '-'}</td>
                  <td className="country-id-td">
                    <FontAwesomeIcon icon={faPaperclip} size="2x" color="#000" />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
        }}>
        Loading...
      </p> : ""}
      {/* <Link className='create-vendor create-button clickable' to={`/create-invoice/${uniCode}`}>Add Vendor</Link> */}
      <div className="add-vendor create-button clickable pointer" onClick={handleShowVendorPopup}>Add Vendor</div>
      {/* <InvoiceExpandPopup showInvoiceExpandPopup={showInvoiceExpandPopup} /> */}
      <VendorPopup
        vendor={vendor}
        vendorAddress={vendorAddress}
        handleVendorInputChange={handleVendorInputChange}
        handleVendorAddressInputChange={handleVendorAddressInputChange}
        handleCountryChange={handleCountryChange}
        handleVendorsChange={handleVendorsChange}
      />
    </>
  )
}

export default React.memo(Vendors)