import { faAngleDown, faExpandArrowsAlt, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { clear } from 'console';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DayDropdown from '../shapes/DayDropdown';
import DatePicker from '../shapes/DatePicker';
import Dropdown from '../shapes/Dropdown';
import '../../css/itinerary.css';
import '../../css/ItineraryExpand.css'
import { Itinerary } from '../../types/Itinerary'
import { ListGroup } from 'react-bootstrap';
import { getCurrentDate, preventCharInput } from '../../utilities/helper';
import { debounce, update } from 'lodash';
import { useParams } from 'react-router-dom';
import ImageSelector from '../shapes/ImageSelector';
import FileUploader from './FileUploader';
import FileDisplay from './FileDisplay';
import ItineraryExpand from './ItineraryExpand';
import { ExpandMore, OpenInFull } from '@mui/icons-material';
import UploadAttachment from './UploadAttachment';
import { Vendor } from '../../types/Vendor';



function ItineraryDetail() {
  const token = localStorage.getItem('token');
  const defaultItinerary = {
    id: 0,
    experience: '',
    day: '',
    date: '',
    description: '',
    p_breakdown: 'PER UNIT',
    number_of_units: 1,
    rate_net_vat: null,
    vatable: '',
    guest_rate: null,
    markup: 20,
    vendorCostNetVat: 0,
    vendorCostVat: 0,
    payVendor: 0,
    guestCostNetVat: 0,
    guestCostVat: 0,
    markupValue: 0,
    markupVat: 0,
    guestBillingAmount: 0,
    grossContributionMargin: 0,
    netContributionMargin: 0,
    popImage: '',
    invoiceImage: '',
    guestBillingNetVat: 0,
    totalVat: 0,
    priceVat: 0,
    vendor_name: '',
    vendor_id: 0,

  }
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [loading, setLoading] = useState(false);
  const vendorListRef = useRef<HTMLDivElement>(null);
  const [vendors, setVendors] = useState<Vendor[]>();
  const [vendor, setVendor] = useState('');
  const fileUPloaderTdRef = useRef<HTMLTableCellElement | null>(null);
  const [rowPending, setRowPending] = useState(false); // Track if a row addition is pending

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisibleRowId, setMenuVisibleRowId] = useState<number | null>(null); // Track the row ID for the context menu
  const [expandedItinerary, setExpandedItinerary] = useState<Itinerary>();

  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [totalItineraries, setTotalItineraries] = useState<Itinerary>();
  const [trip, setTrip] = useState<Itinerary>(defaultItinerary);
  const [numberOfPop, setNumberOfPop] = useState<number>(0);
  const [numberOfInvoices, setNumberOfInvoices] = useState<number>(0);
  const [popWidth, setPopWidth] = useState<string>('1rem');
  const [invoiceWidth, setInvoiceWidth] = useState<string>('1rem');
  const param = useParams();
  const uniItineraryCode = param.uniItineraryCode;

  const days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'ADDITIONAL'];
  const categoryList = ['ACCOMMODATION', 'TRANSPORTATION', 'MEAL', 'EXPERIENCES', 'GUIDES / REPS', 'GROUNDHANDLING', 'OTHER'];
  const pBreakdown = ['LUMP SUM', 'PER UNIT'];
  const vatables = ['VATABLE', 'NOT VATABLE', 'N/A'];
  const optionDayBgColorLight = 'bg-violet-300';
  const optionDayBgColorDark = 'bg-violet-400';
  const optionDayBgColor = [optionDayBgColorLight, optionDayBgColorDark];


  const rowPendingRef = useRef(false);


  const updateFile = (args: { fileNumber, fileName }) => {
    if (args.fileName === 'pop') {
      setNumberOfPop(args.fileNumber);
    }
    if (args.fileName === 'invoice') {
      setNumberOfInvoices(args.fileNumber);
    }
  }

  useEffect(() => {
    setPopWidth((numberOfPop * 3 + 4) + "rem");
  }, [numberOfPop]);

  useEffect(() => {
    setInvoiceWidth((numberOfInvoices * 3 + 4) + "rem");
  }, [numberOfInvoices]);

  const fetchInitialData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/get-trip-fields/${uniItineraryCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const data = response.data;
      if (numberOfPop < data.numberOfPOP) {
        setNumberOfPop(data.numberOfPOP);
      }

      if (numberOfInvoices < data.numberOfInvoices) {
        setNumberOfInvoices(data.numberOfInvoices);
      }

      // Calculate totals
      const initialTotals: Record<string, number> = {};
      const totalItineraryDetail = data.itineraries.reduce((totals, itinerary) => {
        for (const [key, value] of Object.entries(itinerary)) {
          if (typeof value === 'number') {
            totals[key] = (totals[key] || 0) + value;
          }
        }
        return totals;
      }, initialTotals);

      setTotalItineraries(totalItineraryDetail)
      setVendors(response.data.vendors);
      // Update the cell data state
      setItineraries(data.itineraries);
      // setInvoiceWidth((data.numberOfInvoices * 3 + 3) + "rem");
      if (data.itineraries.length === 0) {
        setItineraries([defaultItinerary]);
      } else {
        setItineraries((prevItineraries) => {
          return [...prevItineraries, { ...defaultItinerary, id: prevItineraries[prevItineraries.length - 1].id + 1, description: '', number_of_units: 1, rate_net_vat: null }];
        })
      }
      // console.log('data.itineraries ength', data.itineraries.length);
    } catch (error) {
      console.error(`Error fetching cell data:`, error);
    }
  }

  const handleContextMenu = (e: React.MouseEvent, rowId: number) => {
    e.preventDefault(); // Prevent the default browser context menu
    setMenuVisible(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisibleRowId(rowId); // Track which row is active
  };

  const handleOptionClick = (action: string, rowId: number) => {
    if (action.toLowerCase() === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to delete this itinerary?');
      if (confirmDelete) {
        deleteItineraryDetail(rowId);
      }
    } else if (action.toLowerCase() === 'expand') {

      let selected: any;
      selected = itineraries.find((itinerary) => itinerary.id === rowId);
      if (selected) {
        setExpandedItinerary(selected)
      }
      const itineraryExpand = document.getElementById('itinerary-expand') as HTMLDivElement;
      if (itineraryExpand.classList.contains('show')) {
        itineraryExpand.classList.remove('show')
      } else {
        itineraryExpand.classList.add('show')
      }
    }
    setMenuVisible(false); // Hide the menu after the action
    setMenuVisibleRowId(null); // Reset the active row
  };


  const deleteItineraryDetail = async (rowId: number) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/delete-itinerary-detail/${rowId}`;
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // console.log('response', response.data);

      const updatedItineraries = itineraries.filter((itinerary) => itinerary.id !== rowId);

      // console.log('updatedItineraries', updatedItineraries);

      setItineraries(updatedItineraries); // This should trigger a re-render
    } catch (error) {
      console.error('Error deleting itinerary detail:', error);
    }
  };


  // Hide the context menu when clicking anywhere else
  useEffect(() => {
    const handleClickOutside = () => {
      setMenuVisible(false);
      setMenuVisibleRowId(null);
    };

    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);



  const handleInput = async (e: React.FormEvent<HTMLTableCellElement>) => {
    const newContent = e.currentTarget.textContent || '';
    const currentRow = e.currentTarget.parentElement as HTMLTableRowElement;
    const rowId = parseInt(currentRow.getAttribute('data-id') || '0');
    const cellName = e.currentTarget.getAttribute('data-name') || '';

    updateCellData(rowId, cellName, newContent);
  };

  const vatRate = (vatable: string) => {
    if (vatable === 'VATABLE') {
      return 15;
    } else {
      return 0;
    }
  }

  const updateDataFromDropdown = async (rowId: number, cellName: string, newValue: string) => {
    const row = document.querySelector(`tr[data-id="${rowId}"]`) as HTMLTableRowElement;
    const unitTd = row.querySelector(`td[data-name="number_of_units"]`) as HTMLTableCellElement;
    const unitInput = unitTd.querySelector('input') as HTMLInputElement;
    const unitP = unitTd.querySelector('p') as HTMLParagraphElement;
    if (cellName === 'p_breakdown') {
      if (newValue === 'PER UNIT') {
        // unitInput.readOnly = false;
        unitInput.value = "1";
        // unitInput.style.display = "block";
        // unitP.style.display = "none";

      } else if (newValue === 'LUMP SUM') {

        itineraries.map((itinerary) => {
          if (itinerary.id === rowId) {
            itinerary.number_of_units = 1;
          }
        });
        unitInput.readOnly = true;
        unitInput.style.display = "none";
        unitP.style.display = "block";
        // console.log(unitTd);
      }
    } else if (cellName === 'vatable') {
      itineraries.map((itinerary) => {
        if (itinerary.id === rowId) {
          const vendorCostNetVat = itinerary.vendorCostNetVat ? itinerary.vendorCostNetVat : 0
          itinerary.vendorCostVat = calculateVendorCostVat(vendorCostNetVat, vatRate(newValue));
          console.log("vendorCostNetVat: ", vendorCostNetVat);
          console.log("vendorCostVat: ", itinerary.vendorCostVat);
          itinerary.payVendor = parseFloat(vendorCostNetVat.toString()) + parseFloat(itinerary.vendorCostVat.toString());
          console.log("total: ", parseFloat(vendorCostNetVat.toString()) + parseFloat(itinerary.vendorCostVat.toString()));
          console.log("total: ", itinerary.payVendor);
        }
      });
    }

    // Update `trip` and `itineraries` state with the new value and recalculate `vendorCostNetVat` if necessary
    setTrip((prevTrip) => {
      const updatedTrip = { ...prevTrip, [cellName]: newValue };

      // Check if the updated field affects `vendorCostNetVat`
      if (['rate_net_vat', 'p_breakdown', 'number_of_units'].includes(cellName)) {
        updatedTrip.vendorCostNetVat = calculateVendorCostNetVat(
          Number(updatedTrip.rate_net_vat),
          updatedTrip.p_breakdown,
          Number(updatedTrip.number_of_units)
        );
      }

      return updatedTrip;
    });
    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) =>
        itinerary.id === rowId
          // ? { ...itinerary, id: response.data.experienceId, vatable: newValue }
          ? { ...itinerary, [cellName]: newValue }
          : itinerary
      ));

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`,
      {
        itineraryDetailId: rowId,
        fieldName: cellName,
        value: newValue,
        uniItineraryCode: uniItineraryCode,
        updateNewCreatedRow: false,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) =>
        itinerary.id === rowId
          ? { ...itinerary, id: response.data.experienceId }
          : itinerary
      )
    );

    const tableRow = document.querySelector(`tr[data-id="${rowId}"]`) as HTMLTableRowElement;

    if (tableRow) {
      // change the data-id attribute to the new rowId
      tableRow.setAttribute('data-id', response.data.experienceId);

      // check if it is in the last row
      const table = document.getElementById('itinerary-details-table') as HTMLTableElement;
      const tableBody = table.getElementsByTagName('tbody')[0];
      const tableRows = tableBody.getElementsByTagName('tr');


      // get the row index of the parent element
      // get the index of parentRow
      const parentRowIndex = tableRow.rowIndex;
      if (parentRowIndex == (tableRows.length - 1)) {
        const newRow = { ...defaultItinerary, id: response.data.experienceId + 1, description: '', number_of_units: 1, rate_net_vat: null };
        setItineraries((prevItineraries) => [...prevItineraries, newRow]);
        // console.log("newRow: ", newRow);
      }
    }

  }


  const updateCellData = async (rowId: number, cellName: string, newValue: string) => {
    try {
      // console.log('updateCellData', rowId, cellName, newValue);
      setItineraries((prevItineraries) =>
        prevItineraries.map((itinerary) => {
          if (itinerary.id === rowId) {
            // Create a copy of the itinerary and update the specified cell
            let updatedItinerary = { ...itinerary, [cellName]: newValue };

            // Check if `vendorCostNetVat` needs to be recalculated
            if (cellName === 'rate_net_vat' || cellName === 'p_breakdown' || cellName === 'number_of_units') {
              if (newValue === 'LUMP SUM') {
                updatedItinerary.number_of_units = 1;
              }
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(
                cellName === 'rate_net_vat' ? Number(newValue) : itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0,
                cellName === 'p_breakdown' ? newValue : itinerary.p_breakdown,
                cellName === 'number_of_units' ? Number(newValue) : itinerary.number_of_units
              );
            } else {
              updatedItinerary.vendorCostNetVat = itinerary.vendorCostNetVat;
            }

            // console.log("updateCellDate: ", itineraries[0].vendorCostNetVat)
            return updatedItinerary;
          } else {
            // If this is not the matching row, return the original itinerary unchanged
            // console.log("updateCellDate: ", itineraries[0].vendorCostNetVat)
            return itinerary;
          }
        })
      );

    } catch (error) {
      console.error("Error updating cell data:", error);
    }
  };
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const debouncedUpdateCellData = useCallback(
    debounce(async (rowId: number, cellName: string, newValue: string, isLastRow: boolean) => {
      // if (rowPendingRef.current) return; // Prevent multiple row additions

      try {
        if (rowPendingRef.current) {
          rowPendingRef.current = true; // Set the ref to true immediately
          // send axios request with flag = true
          // !! markup is save by default 20% if it is on create in laravel TTPController
          await delay(1000); // Wait for 1 second
          const data = {
            itineraryDetailId: rowId,
            fieldName: cellName,
            value: newValue,
            uniItineraryCode: uniItineraryCode,
            updateNewCreatedRow: true,
            // markup: markup
          }
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`, data,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const table = document.getElementById('itinerary-details-table') as HTMLTableElement;
          const tableBody = table.getElementsByTagName('tbody')[0];
          const tableRows = tableBody.getElementsByTagName('tr');
          setItineraries((prevItineraries) =>
            prevItineraries.map((itinerary) =>
              itinerary.id === rowId
                ? { ...itinerary, id: response.data.experienceId }
                : itinerary
            )
          );
        } else {
          rowPendingRef.current = true; // Set the ref to true immediately
          const data = {
            itineraryDetailId: rowId,
            fieldName: cellName,
            value: newValue,
            uniItineraryCode: uniItineraryCode,
            updateNewCreatedRow: false,
            // markup: markup
          }
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`, data,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const table = document.getElementById('itinerary-details-table') as HTMLTableElement;
          const tableBody = table.getElementsByTagName('tbody')[0];
          const tableRows = tableBody.getElementsByTagName('tr');
          if (itineraries && response.data) {
            // add new itinerary if isLastRow is true

            setItineraries((prevItineraries) =>
              prevItineraries.map((itinerary) =>
                itinerary.id === rowId
                  ? { ...itinerary, id: response.data.experienceId }
                  : itinerary
              )
            );
            // Add a new itinerary if this is the last row
            if (isLastRow && response.status === 200) {
              setItineraries((prevItineraries) => [
                ...prevItineraries,
                { ...defaultItinerary, id: response.data.experienceId + 1 },
              ]);
            }
            // Update DOM for the new row
            const table = document.getElementById('itinerary-details-table') as HTMLTableElement;
            const tableBody = table.getElementsByTagName('tbody')[0];
            const tableRows = tableBody.getElementsByTagName('tr');

            if (tableRows.length == 2) {
              tableRows[1].setAttribute('data-id', response.data.experienceId + 1);
              tableRows[1].classList.remove('pointer-events-none');
            }
          }
        }
        // console.log("Cell data updated successfully!", response.data);
      } catch (error) {
        console.error("Error updating cell data:", error);
      } finally {
        rowPendingRef.current = false; // Reset the ref value
      }
    }, 250),
    [] // Empty dependency array to ensure it's defined only once
  );


  const handleInputChange = useCallback(
    (
      rowId: number,
      cellName: string,
      value: string,
      title: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
      // Update the itineraries state
      setItineraries((prevItineraries) => {
        const vatRate = (vatable: string) => {
          if (vatable === 'VATABLE') {
            return 15;
          } else {
            return 0;
          }
        }
        const updatedItineraries = prevItineraries.map((itinerary) => {
          if (itinerary.id === rowId) {
            let updatedItinerary = { ...itinerary, [cellName]: value };

            if (cellName === 'rate_net_vat' || cellName === 'p_breakdown' || cellName === 'number_of_units') {
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(
                cellName === 'rate_net_vat' ? Number(value) : itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0,
                cellName === 'p_breakdown' ? value : itinerary.p_breakdown,
                cellName === 'number_of_units' ? Number(value) : itinerary.number_of_units
              );

              // console.log("vendorCostNetVat: " + updatedItinerary.vendorCostNetVat + " vatable: " + itinerary.vatable + " vatRate: " + vatRate(itinerary.vatable));
              updatedItinerary.vendorCostVat = calculateVendorCostVat(
                updatedItinerary.vendorCostNetVat,
                vatRate(itinerary.vatable)
              );
              updatedItinerary.payVendor = updatedItinerary.vendorCostNetVat + updatedItinerary.vendorCostVat;
              // updatedItinerary.guest_rate = updatedItinerary.rate_net_vat;

              const rate = updatedItinerary.rate_net_vat !== null ? updatedItinerary.rate_net_vat : 0;
              const unit = updatedItinerary.number_of_units;
              const payVendor = updatedItinerary.payVendor ? updatedItinerary.payVendor : 0;
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(rate, updatedItinerary.p_breakdown, unit);
              // const vendorCostNetVat = updatedItinerary.vendorCostNetVat ? updatedItinerary.vendorCostNetVat : 0;
              updatedItinerary.vendorCostVat = calculateVendorCostVat(updatedItinerary.vendorCostNetVat, vatRate(itinerary.vatable));
              updatedItinerary.guestCostNetVat = (updatedItinerary.guest_rate !== null ? updatedItinerary.guest_rate : 0) * (cellName === "number_of_units" ? parseInt(value) : itinerary.number_of_units);
              console.clear();
              console.log((updatedItinerary.guest_rate !== null ? updatedItinerary.guest_rate : 0) * itinerary.number_of_units)
              updatedItinerary.guestCostVat = updatedItinerary.guestCostNetVat * 0.15;
              updatedItinerary.markupValue = updatedItinerary.guestCostNetVat
                ? updatedItinerary.guestCostNetVat * (updatedItinerary.markup / 100)
                : 0;
              updatedItinerary.markupVat = updatedItinerary.markupValue ? updatedItinerary.markupValue * 0.15 : 0;
              updatedItinerary.guestBillingAmount =
                updatedItinerary.guestCostNetVat +
                updatedItinerary.guestCostVat +
                updatedItinerary.markupValue +
                updatedItinerary.markupVat;

              updatedItinerary.guestBillingNetVat = updatedItinerary.guestCostNetVat + updatedItinerary.markupValue;
              updatedItinerary.totalVat = updatedItinerary.guestCostVat + updatedItinerary.markupVat;
              updatedItinerary.priceVat = rate * (1 + vatRate(updatedItinerary.vatable));
              updatedItinerary.grossContributionMargin = updatedItinerary.guestBillingAmount - payVendor;
              // } else if (cellName === 'guest_rate' || cellName === 'number_of_units' || cellName === 'markup') {
            } else if (cellName === 'guest_rate' || cellName === 'number_of_units') {
              const rate = updatedItinerary.rate_net_vat !== null ? updatedItinerary.rate_net_vat : 0;
              const unit = updatedItinerary.number_of_units;
              const payVendor = updatedItinerary.payVendor ? updatedItinerary.payVendor : 0;
              // let result = updatedItinerary.p_breakdown === 'PER UNIT' ? rate * unit : rate;
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(rate, updatedItinerary.p_breakdown, unit);
              // const vendorCostNetVat = updatedItinerary.vendorCostNetVat ? updatedItinerary.vendorCostNetVat : 0;
              updatedItinerary.vendorCostVat = calculateVendorCostVat(updatedItinerary.vendorCostNetVat, vatRate(itinerary.vatable));
              updatedItinerary.guestCostNetVat = (updatedItinerary.guest_rate !== null ? updatedItinerary.guest_rate : 0) * itinerary.number_of_units;
              updatedItinerary.guestCostVat = updatedItinerary.guestCostNetVat * 0.15;
              updatedItinerary.markupValue = updatedItinerary.guestCostNetVat
                ? updatedItinerary.guestCostNetVat * (updatedItinerary.markup / 100)
                : 0;
              updatedItinerary.markupVat = updatedItinerary.markupValue ? updatedItinerary.markupValue * 0.15 : 0;
              updatedItinerary.guestBillingAmount =
                updatedItinerary.guestCostNetVat +
                updatedItinerary.guestCostVat +
                updatedItinerary.markupValue +
                updatedItinerary.markupVat;
              updatedItinerary.netContributionMargin = updatedItinerary.guestBillingAmount - payVendor - updatedItinerary.guestCostVat - updatedItinerary.markupVat + updatedItinerary.vendorCostVat;
              updatedItinerary.grossContributionMargin = updatedItinerary.guestBillingAmount - payVendor;
              console.clear();
              console.log(updatedItinerary.guestCostNetVat + " " + updatedItinerary.markupValue);
              updatedItinerary.guestBillingNetVat = updatedItinerary.guestCostNetVat + updatedItinerary.markupValue;
              updatedItinerary.totalVat = updatedItinerary.guestCostVat + updatedItinerary.markupVat;
              updatedItinerary.priceVat = rate * (1 + vatRate(updatedItinerary.vatable));
            } else if (cellName === 'markup') {
              // console.log('cellName - markup : ', updatedItinerary ? updatedItinerary[0] : '');
              const guestCostNetVat = updatedItinerary.guest_rate !== null ? updatedItinerary.guest_rate : 0;
              const guestCostVat = guestCostNetVat * 0.15;

              updatedItinerary.markupValue = updatedItinerary.guestCostNetVat
                ? updatedItinerary.guestCostNetVat * (numericValue / 100)
                : 0;
              updatedItinerary.markupVat = updatedItinerary.markupValue ? updatedItinerary.markupValue * 0.15 : 0;
              let numberOfUnits = updatedItinerary.number_of_units ? updatedItinerary.number_of_units : 1;
              updatedItinerary.guestBillingAmount =
                (guestCostNetVat * numberOfUnits) +
                (guestCostVat * numberOfUnits) +
                updatedItinerary.markupValue +
                updatedItinerary.markupVat;
            }
            // console.log('cellName - markup : ', updatedItinerary ? updatedItinerary[0] : '');
            return updatedItinerary;
          }
          return itinerary;
        });
        // Check if the current row is the last one; if so, add a new row
        // console.log(prevItineraries);
        // if (prevItineraries[prevItineraries.length - 1].id === rowId) {
        //   const newRow = { ...defaultItinerary, id: rowId + 1, description: '', number_of_units: 1, rate_net_vat: null };
        //   return [...updatedItineraries, newRow];
        // }

        return updatedItineraries;
      });

      const table = document.getElementById('itinerary-details-table') as HTMLTableElement;
      const tableBody = table.getElementsByTagName('tbody')[0];
      // console.clear();
      // console.log(table);
      // console.log(tableBody);
      const tableRows = tableBody.getElementsByTagName('tr');
      console.log(tableRows);

      if (tableRows.length == 2) {
        tableRows[1].classList.add('pointer-events-none');
      }
      // Additional updates
      const itinerary = itineraries.find((itinerary) => itinerary.id === rowId);
      if (itinerary) {
        displayTableCells(title, itinerary);
      }

      // Update trip state if the edited row matches trip.id
      if (trip.id === rowId) {
        setTrip((prevTrip) => ({
          ...prevTrip,
          [cellName]: value,
          vendorCostNetVat: calculateVendorCostNetVat(
            cellName === 'rate_net_vat' ? Number(value) : prevTrip.rate_net_vat !== null ? prevTrip.rate_net_vat : 0,
            cellName === 'p_breakdown' ? value : prevTrip.p_breakdown,
            cellName === 'number_of_units' ? Number(value) : prevTrip.number_of_units
          ),
        }));
      }
      // get the row index of the parent element
      const parentRow = e.target.closest('tr') as HTMLTableRowElement;
      // get the index of parentRow
      const parentRowIndex = parentRow.rowIndex;
      let isLastRow = false
      if (parentRowIndex === tableRows.length - 1) {
        isLastRow = true;
      }
      // Update cell data on the server
      debouncedUpdateCellData(rowId, cellName, value, isLastRow);
    },
    [debouncedUpdateCellData] // Only update if debounced function changes
  );

  useEffect(() => {
    // console.clear();
    console.log("itineraries from use effect:", itineraries);
    // console.log(itineraries[0].vendorCostNetVat);
    // console.log('useEffect - vendorCostNetVat: ' + itineraries[0].vendorCostNetVat + " vendorCostVat:" + itineraries[0].vendorCostVat);
  }, [itineraries]);
  // onBlur handler for formatting
  const handleBlur = (rowId: number) => {
    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) => {
        if (itinerary.id === rowId) {
          let rateNetVat: any = null;
          let guestRate: any = null;
          if (typeof itinerary.rate_net_vat === 'string') {
            rateNetVat = itinerary.rate_net_vat;
            rateNetVat = parseFloat(formatNumber(rateNetVat.replace(/,/g, '')));
          } else if (typeof itinerary.rate_net_vat === 'number') {
            rateNetVat = itinerary.rate_net_vat;
          }
          if (typeof itinerary.guest_rate === 'string') {
            guestRate = itinerary.guest_rate;
            guestRate = parseFloat(formatNumber(guestRate.replace(/,/g, '')));
          } else if (typeof itinerary.guest_rate === 'number') {
            guestRate = itinerary.guest_rate;
          }
          return {
            ...itinerary,
            rate_net_vat: itinerary.rate_net_vat !== null ? rateNetVat : null,
            guest_rate: itinerary.guest_rate !== null ? guestRate : null,
          };
        }
        return itinerary;
      })
    );
  };

  const calculateVendorCostNetVat = (rate: number, pBreakdown: string, units: number) => {
    // console.clear();
    // console.log("rate: " + rate, "pBreakdown: " + pBreakdown, "units: " + units);
    let result = pBreakdown === 'PER UNIT' ? rate * units : rate;
    return result;
  };

  const calculateVendorCostVat = (vendorCostNetVat: number, vatRate: number) => {
    return vendorCostNetVat * (vatRate / 100);
  }

  const displayTableCells = (title: string, itinerary: Itinerary) => {
    let result: any = null;
    let vatRate: number | null = null;
    let vendorCostNetVat: number | null = null;
    let vendorCostVat: number | null = null;
    let guestCostNetVat: number | null = null;
    let guestCostVat: number | null = null;
    let markupValue: number | null = null;
    const vatRateFunction = (vatable: string) => {
      if (vatable === 'VATABLE') {
        return 15;
      } else {
        return 0;
      }
    }
    if (title === 'Experience') {
      result = itinerary.experience;
    } else if (title === 'Day') {
      result = itinerary.day;
    } else if (title === 'Date') {
      result = itinerary.date;
    } else if (title === 'Description') {
      result = itinerary.description;
    } else if (title === 'P.Breakdown') {
      result = itinerary.p_breakdown;
    } else if (title === 'number_of_units') {
      result = itinerary.number_of_units;

    } else if (title === 'RATE - Net Vat') {
      result = vatRate = itinerary.rate_net_vat;
    } else if (title === 'VATABLE') {
      result = itinerary.vatable;
    } else if (title === 'Vendor Cost - Net Vat') {
      result = vendorCostNetVat = calculateVendorCostNetVat(itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0, itinerary.p_breakdown, itinerary.number_of_units);
      // console.log(result);
    } else if (title === 'Vendor Cost Vat') {
      // result = vendorCostVat = vendorCostNetVat && vatRate ? vendorCostNetVat * (vatRate / 100) : 0;
      // console.clear();
      // console.log(itinerary.rate_net_vat);
      result = vendorCostVat = calculateVendorCostVat(calculateVendorCostNetVat(itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0, itinerary.p_breakdown, itinerary.number_of_units), vatRateFunction(itinerary.vatable));
      // setItineraries((prevItineraries) =>
      //   prevItineraries.map((itinerary) =>
      //     itinerary.id === itinerary.id
      //       ? { ...itinerary, vendorCostVat: vendorCostVat }
      //       : itinerary
      //   )
      // );
    } else if (title === 'PAY VENDOR') {
      // console.log('PAY VENDOR');
      // console.log(itinerary.payVendor ? itinerary.payVendor : 0);
      result = itinerary.payVendor ? itinerary.payVendor : 0;
    } else if (title === 'GUEST RATE') {
      result = itinerary.guest_rate;
    } else if (title === 'Guest Cost - NET VAT') {
      result = guestCostNetVat = (itinerary.guest_rate !== null ? itinerary.guest_rate : 0) * itinerary.number_of_units;
    } else if (title === 'Guest Cost VAT') {
      result = guestCostVat = itinerary.guestCostNetVat ? itinerary.guestCostNetVat * 0.15 : 0;
    } else if (title === 'Mark Up') {
      result = itinerary.markup;
    } else if (title === 'Mark Up VALUE') {
      result = markupValue = itinerary.guestCostNetVat ? itinerary.guestCostNetVat * (itinerary.markup / 100) : 0;
    } else if (title === 'Mark Up VAT') {
      result = itinerary.markupValue != null ? itinerary.markupValue * .15 : null;
    } else if (title === 'GUEST BILLING AMOUNT') {
      result = itinerary.guestCostNetVat != null && itinerary.guestCostVat != null && itinerary.markupValue != null && itinerary.markupVat != null ? itinerary.guestCostNetVat + itinerary.guestCostVat + itinerary.markupValue + itinerary.markupVat : null;
    } else if (title === 'GROSS CONTRIBUTION MARGIN') {
      result = itinerary.guestBillingAmount && itinerary.payVendor ? itinerary.guestBillingAmount - itinerary.payVendor : 0;
    } else if (title === 'NET CONTRIBUTION MARGIN') {
      // console.log("guestBillingAmount: " + itinerary.guestBillingAmount + " payVendor: " + itinerary.payVendor + " guestCostVat: " + itinerary.guestCostVat + " markupVat: " + itinerary.markupVat + " vendorCostVat: " + itinerary.vendorCostVat);
      result = itinerary.guestBillingAmount != null && itinerary.payVendor != null && itinerary.guestCostVat != null && itinerary.markupVat != null && itinerary.vendorCostVat != null
        ?
        itinerary.guestBillingAmount - itinerary.payVendor - itinerary.guestCostVat - itinerary.markupVat + itinerary.vendorCostVat : 'cat';
    } else if (title === 'GUEST BILLING - NET VAT') {
      result = itinerary.guestCostNetVat != null && itinerary.markupValue != null ? itinerary.guestCostNetVat + itinerary.markupValue : null;
      // console.log("guest billing net vat: " + itinerary.guestCostNetVat + " + " + itinerary.markupValue + " = " + result);
    } else if (title === 'TOTAL VAT') {
      result = itinerary.guestCostVat != null && itinerary.markupVat != null ? itinerary.guestCostVat + itinerary.markupVat : null;
      // console.log("total vat: " + itinerary.guestCostVat + " + " + itinerary.markupVat + " = " + result);
    } else if (title === 'PRICE VAT') {
      // console.log("price vat: " + itinerary.rate_net_vat + " * " + (vatRateFunction(itinerary.vatable) ? (1 + vatRateFunction(itinerary.vatable) / 100) : 1 + 0) + " = " + result);
      result = itinerary.rate_net_vat != null ? itinerary.rate_net_vat * (vatRateFunction(itinerary.vatable) ? (1 + vatRateFunction(itinerary.vatable) / 100) : 1 + 0) : 0;
    }

    // add comma seperator and 2 decimal places
    if (result !== null && result !== undefined) {
      if (title !== 'number_of_units' && title !== 'Mark Up') {
        result = result.toLocaleString('en-US');
      }
    }
    return result;
  }

  const formatNumber = (value: number | null) => {
    if (value === null) return '';
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleShowVendorList = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement;
    const clientList = target.nextElementSibling as HTMLElement;
    if (clientList.style.display == "") {
      const allClientList = document.querySelectorAll('.client-list') as NodeListOf<HTMLElement>;
      for (let i = 0; i < allClientList.length; i++) {
        const cl = allClientList[i];
        if (cl !== clientList) {
          cl.style.display = '';
        }
      }
      setTimeout(() => {
        clientList.style.display = 'block';
      }, 0);
    } else {
      clientList.style.display = '';
    }
  }

  const handleShowVendorForm = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const clientList = target.closest('.client-list') as HTMLElement;
    const clientFormPopup = clientList.nextSibling as HTMLElement;

    if (clientFormPopup.style.display == "") {
      setTimeout(() => {
        clientFormPopup.style.display = 'block';
        clientList.style.display = '';
      }, 0);
    } else {
      clientFormPopup.style.display = '';
      clientList.style.display = 'block';
    }
  };

  const handleVendorSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    const clientListUl = document.querySelector('.client-list ul') as HTMLElement;
    const lis = clientListUl.getElementsByTagName('li');
    for (let i = 0; i < lis.length; i++) {
      const li = lis[i];
      if (li.textContent!.toLowerCase().includes(search.toLowerCase())) {
        setTimeout(() => {
          li.style.display = 'block';
        }, 0);
      } else {
        li.style.display = 'none';
      }
    }
  }

  const handleShowSelectedVendor = (e: MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget as HTMLLIElement;
    const clientSection = target.closest('.client-section') as HTMLElement;
    const clientElement = clientSection.querySelector('p')
    const clientList = target.closest('.client-list') as HTMLElement;
    const itineraryId = target.closest('tr')!.getAttribute('data-id');
    const clientId = target.getAttribute('data-id')!;

    clientElement!.innerHTML = target.innerHTML.split('-')[0].trim();
    clientElement!.setAttribute('data-id', target.getAttribute('data-id')!);
    setVendor(target.innerHTML.split('-')[0].trim());
    clientList.style.display = '';
    // alert(itineraryId)
    if (itineraryId) {
      updateItineraryVendor(parseInt(itineraryId), parseInt(clientId));
    }
  }

  const updateItineraryVendor = async (itineraryId: number, vendorId: number) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary-vendor`;
    try {
      const response = await axios.post(
        url,
        { itineraryId, vendorId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedItinerary = response.data.itinerary;
      setItineraries((prev) =>
        prev.map((itinerary) =>
          itinerary.id === itineraryId
            ? { ...itinerary, vendor_id: vendorId, client_name: updatedItinerary.client_name }
            : itinerary
        )
      );
    } catch (error) {
      console.error('Error updating client', error);
    }
  };

  const handleHideVendorForm = () => {
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    clientFormPopup.style.display = '';
    setTimeout(() => {
      clientList.style.display = 'block';
    }, 0);
  }


  const handleAddNewVendor = async (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;;
    // const vendorFormPopup = document.querySelector('.vendor-form-popup') as HTMLElement;
    const vendorFormPopup = target.closest('.client-form-popup') as HTMLElement;
    const targetTd = vendorFormPopup.closest('.custom-cell') as HTMLElement;
    const vendorList = targetTd.querySelector('.client-list') as HTMLElement;
    const name = (vendorFormPopup.querySelector('.client-form-popup input[type="text"]') as HTMLInputElement).value;
    const email = (vendorFormPopup.querySelector('.client-form-popup input[type="email"]') as HTMLInputElement).value;
    // !! workspace_id is hardcoded since this enterprise is for TTP only
    const data = { name: name, email: email, workspace_id: 1, customerCode: '123' };
    const vendorFormSubmit = vendorFormPopup.querySelector('.create-client-button') as HTMLButtonElement;
    vendorFormSubmit.classList.add('inactive');
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-vendor`;
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const vendorListUl = targetTd.querySelector('.client-list ul') as HTMLElement;
      const li = document.createElement('li');
      li.addEventListener('click', handleShowSelectedVendor);
      li.textContent = name + " - " + email;
      li.setAttribute('data-id', response.data.vendorId)
      vendorListUl.appendChild(li);
      setShowAlert(true);
      setAlertBody('Vendor has been added successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error('Error creating vendor', error);
      setShowAlert(true);
      setAlertBody('Client has not been added!');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    vendorFormPopup.style.display = '';
    setTimeout(() => {
      vendorList.style.display = 'block';
    }, 0);
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    const newVendorCostNetVat = calculateVendorCostNetVat(trip.rate_net_vat !== null ? trip.rate_net_vat : 0, trip.p_breakdown, trip.number_of_units);

    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) =>
        itinerary.id === trip.id
          ? { ...itinerary, vendorCostNetVat: newVendorCostNetVat }
          : itinerary
      )
    );
  }, [trip]);

  useEffect(() => {
    const totals = itineraries.reduce(
      (acc: {
        id: number;
        experience: string;
        day: string;
        date: string;
        description: string;
        p_breakdown: string;
        number_of_units: number;
        rate_net_vat: number;
        vatable: string;
        guest_rate: number;
        markup: number;
        vendorCostNetVat: number;
        vendorCostVat: number;
        payVendor: number;
        guestCostNetVat: number;
        guestCostVat: number;
        markupValue: number;
        markupVat: number;
        guestBillingAmount: number;
        grossContributionMargin: number;
        netContributionMargin: number;
        popImage: string;
        invoiceImage: string;
        guestBillingNetVat: number;
        totalVat: number;
        priceVat: number;
        vendor_name: string;
        vendor_id: number;
      }, itinerary) => {
        // console.log('acc.rate_net_vat: ' + acc.rate_net_vat + ' itinerary.rate_net_vat: ' + itinerary.rate_net_vat);
        if (itinerary.rate_net_vat !== null) {
          acc.rate_net_vat += parseFloat(itinerary.rate_net_vat.toString()) ?? 0;
        } else {
          acc.rate_net_vat += 0;

        }
        if (itinerary.vendorCostNetVat !== null) {
          acc.vendorCostNetVat += parseFloat(itinerary.vendorCostNetVat.toString()) ?? 0;
        } else {
          acc.vendorCostNetVat += 0
        }
        if (itinerary.vendorCostVat !== null) {
          acc.vendorCostVat += parseFloat(itinerary.vendorCostVat.toString()) ?? 0;
        } else {
          acc.vendorCostVat += 0;
        }
        if (itinerary.payVendor !== null) {
          acc.payVendor += parseFloat(itinerary.payVendor.toString()) ?? 0;
        } else {
          acc.payVendor += 0;
        }
        if (itinerary.guest_rate !== null) {
          acc.guest_rate += parseFloat(itinerary.guest_rate.toString()) ?? 0;
        } else {
          acc.guest_rate += 0;
        }
        if (itinerary.guestCostNetVat !== null) {
          acc.guestCostNetVat += parseFloat(itinerary.guestCostNetVat.toString()) ?? 0;
        } else {
          acc.guestCostNetVat += 0;
        }
        if (itinerary.guestCostVat !== null) {
          acc.guestCostVat += parseFloat(itinerary.guestCostVat.toString()) ?? 0;
        } else {
          acc.guestCostVat += 0;
        }
        if (itinerary.markupValue !== null) {
          acc.markupValue += parseFloat(itinerary.markupValue.toString()) ?? 0;
        } else {
          acc.markupValue += 0;
        }
        if (itinerary.markupVat !== null) {
          acc.markupVat += parseFloat(itinerary.markupVat.toString()) ?? 0;
        } else {
          acc.markupVat += 0;
        }
        if (itinerary.guestBillingAmount !== null) {
          acc.guestBillingAmount += parseFloat(itinerary.guestBillingAmount.toString()) ?? 0;
        } else {
          acc.guestBillingAmount += 0;
        }
        if (itinerary.grossContributionMargin !== null) {
          acc.grossContributionMargin += parseFloat(itinerary.grossContributionMargin.toString()) ?? 0;
        } else {
          acc.grossContributionMargin += 0;
        }
        if (itinerary.netContributionMargin !== null) {
          acc.netContributionMargin += parseFloat(itinerary.netContributionMargin.toString()) ?? 0;
          console.log("total netContributionMargin", acc.netContributionMargin);
        } else {
          acc.netContributionMargin += 0;
        }
        if (itinerary.guestBillingNetVat !== null) {
          console.log(">>>>>>itinerary.guestBillingNetVat: " + itinerary.guestBillingNetVat);
          acc.guestBillingNetVat += parseFloat(itinerary.guestBillingNetVat.toString()) ?? 0;
          console.log("total guestBillingNetVat", acc.guestBillingNetVat);
        } else {
          acc.guestBillingNetVat += 0;
        }
        if (itinerary.totalVat !== null) {
          acc.totalVat += parseFloat(itinerary.totalVat.toString()) ?? 0;
          console.log("total totalVat", acc.totalVat);
        } else {
          acc.totalVat += 0;
        }
        if (itinerary.priceVat !== null) {
          acc.priceVat += parseFloat(itinerary.priceVat.toString()) ?? 0;
          console.log("total priceVat", acc.priceVat);
        } else {
          acc.priceVat += 0;
        }
        return acc;
      },
      {
        id: 0,
        experience: '',
        day: '',
        date: '',
        description: '',
        p_breakdown: 'PER UNIT',
        number_of_units: 1,
        rate_net_vat: 0,
        vatable: '',
        guest_rate: 0,
        markup: 0,
        vendorCostNetVat: 0,
        vendorCostVat: 0,
        payVendor: 0,
        guestCostNetVat: 0,
        guestCostVat: 0,
        markupValue: 0,
        markupVat: 0,
        guestBillingAmount: 0,
        grossContributionMargin: 0,
        netContributionMargin: 0,
        popImage: '',
        invoiceImage: '',
        guestBillingNetVat: 0,
        totalVat: 0,
        priceVat: 0,
        vendor_name: '',
        vendor_id: 0,
      }
    );

    console.log("totals", totals);
    setTotalItineraries(totals);
  }, [itineraries]);

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    target.value = target.value.replace(/,/g, '');
  }

  const handleClickOutside = (e: MouseEvent) => {
    const allClientList = document.querySelectorAll('.client-list') as NodeListOf<HTMLElement>;
    const target = e.target as HTMLElement;

    allClientList.forEach(element => {
      if (!element.contains(target)) {
        const style = window.getComputedStyle(element);
        if (style.display === 'block') {
          element.style.display = 'none';
        }
      }
    });

    hideDialogues();
  };

  const hideDialogues = () => {
    const dialogues = document.getElementsByClassName('dialogue') as HTMLCollectionOf<HTMLElement>;
    if (dialogues.length) {
      for (let i = 0; i < dialogues.length; i++) {
        const dialogue = dialogues[i];
        if (dialogue.style.display === 'block') {
          dialogue.style.display = 'none';
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="overflow-auto rounded-lg shadow-lg h-[100vh]">
      <TableContainer component={Paper} style={{ height: '100vh', marginTop: '0px', overflowY: 'auto' }}>
        <Table id='itinerary-details-table' stickyHeader>
          <TableHead>
            <TableRow className="table-header">
              <TableCell padding="checkbox" className='w-[25px]' style={{ width: '25px' }} />
              <TableCell padding="none" className='w-[25px]' />
              <TableCell padding="none" className='w-[25px]' />
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r sticky left-0 group' data-name='Category'>Cate...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  Category
                </span>
              </TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Day</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Date</TableCell>
              <TableCell className=' custom-header-cell align-center i-border-r min-w-[186px]'>ITEM DESCRIPTION</TableCell>
              {/* <TableCell className="w-[5%] custom-header-cell align-center i-border-r group relative">Break...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  Breakdown
                </span>
              </TableCell> */}
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>Units</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r group relative' data-name="RATE - Net Vat">PRICE N...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  RATE - Net Vat
                </span>
              </TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>VATABLE</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>VAT RATE</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Vendor Cost - Net Vat</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Vendor Cost VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>PAY VENDOR</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r group relative' data-name='GUEST RATE'>GUEST R...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  GUEST RATE
                </span>
              </TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Guest Cost - NET VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Guest Cost VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>Mark UP%</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Mark Up VALUE</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>Mark Up VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>GUEST BILLING AMOUNT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>GROSS CONTRIBUTION MARGIN</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r group relative' data-name='NET MARGIN'>NET MAR...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  NET CONTRIBUTION MARGIN
                </span>
              </TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r group relative' data-name='GUEST BILLING - NET VAT'>GUEST B...
                <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  GUEST BILLING - NET VAT
                </span>
              </TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>TOTAL VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r overnight'>PRICE VAT</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>Vendor</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>Invoice</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r'>POP</TableCell>
              <TableCell className='w-[5%] custom-header-cell align-center i-border-r group relative' data-name='Payment Status'>Payment...
                <span className="absolute left-[-30px] top-0 hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                  Payment Status
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {itineraries && Array.isArray(itineraries) && itineraries.map((itinerary, index) => (
              <TableRow key={index}
                className="table-row even:bg-gray-50 hover:bg-blue-50 transition-colors cursor-pointer tr"
                data-id={itinerary.id}>
                <TableCell padding="checkbox" style={{ width: '25px' }}>
                  <div className="w-100 h-100 flex justify-center">
                    <Checkbox />
                  </div>
                </TableCell>
                <TableCell padding="none">
                  <div className="w-100 h-100 flex justify-center">
                    <IconButton>
                      <ExpandMore />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell padding="none">
                  <div className="w-100 h-100 flex justify-center">
                    <IconButton>
                      <OpenInFull
                        className='expand-icon pointer'
                        onClick={() => handleOptionClick('Expand', itinerary.id)}
                        onContextMenu={(e) => handleContextMenu(e, itinerary.id)} />
                      {menuVisible && menuVisibleRowId === itinerary.id && (
                        <ul className='context-menu' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
                          <li className="border-b border-gray-200 hover:bg-gray-100" style={{ padding: '8px 12px', cursor: 'pointer' }}
                            onClick={() => handleOptionClick('Delete', itinerary.id)}
                          >Delete</li>
                        </ul>
                      )}
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell className={`custom-cell sticky left-0 border-r-2 border-r-black`} data-name="experience" style={{ zIndex: (999 - index) }}>
                  <div className="relative flex justify-center">
                    <Dropdown
                      items={categoryList}
                      selectedItem={displayTableCells('Experience', itinerary)}
                      defaultSelectedItem='-'
                      onChange={(selectedItem) => updateDataFromDropdown(itinerary.id, "experience", selectedItem)}
                      otherClasses='w-[83px] h-[21px]'
                      fieldName='category'
                    />
                  </div>
                </TableCell>
                <TableCell className="custom-cell i-border-r overnight" data-name="day">
                  <DayDropdown
                    days={days}
                    selectedDay={displayTableCells('Day', itinerary)}
                    defaultSelectedDay="1"
                    onChange={(selectedDay) => updateDataFromDropdown(itinerary.id, "day", selectedDay)}
                  />
                </TableCell>
                <TableCell className="custom-cell i-border-r overnight" data-name="date">
                  <DatePicker
                    initialDate={displayTableCells('Date', itinerary)}
                    defaultSelectedDate={getCurrentDate()}
                    onDateChange={(formattedDate) => updateDataFromDropdown(itinerary.id, "date", formattedDate)}
                  />
                </TableCell>
                <TableCell className="custom-cell i-border-r" data-name="description">
                  <input
                    type="text"
                    value={displayTableCells('Description', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, 'Description', e)}
                    className="border-none outline-none bg-transparent w-full"
                  />
                </TableCell>
                {/* <TableCell className="custom-cell i-border-r" data-name="breakdown">
                  <Dropdown
                    items={pBreakdown}
                    selectedItem={displayTableCells('P.Breakdown', itinerary)}
                    defaultSelectedItem='LUMP SUM'
                    onChange={(selectedItem) => updateDataFromDropdown(itinerary.id, "p_breakdown", selectedItem)}
                    otherClasses='w-[85px] h-[21px]'
                  />
                </TableCell> */}
                <TableCell className="custom-cell i-border-r" data-name="number_of_units">
                  <input
                    type="text"
                    value={displayTableCells('number_of_units', itinerary)}
                    onKeyDown={preventCharInput}
                    onChange={(e) => handleInputChange(itinerary.id, 'number_of_units', e.target.value, 'number_of_units', e)}
                    className="border-none outline-none bg-transparent w-full"
                    style={{ textAlign: 'right' }}
                  />
                  {/* <p style={{ display: `${itinerary.p_breakdown === 'PER UNIT' ? 'none' : 'block'}`, textAlign: 'right' }}>-</p> */}
                </TableCell>
                <TableCell className="custom-cell i-border-r" data-name="rate_net_vat">
                  <input
                    type="text"
                    value={displayTableCells('RATE - Net Vat', itinerary)}
                    onKeyDown={preventCharInput}
                    onChange={(e) => handleInputChange(itinerary.id, 'rate_net_vat', e.target.value, 'RATE - Net Vat', e)}
                    onBlur={() => handleBlur(itinerary.id)}
                    onFocus={handleInputFocus}
                    className="border-none outline-none bg-transparent w-full text-right" style={{ textAlign: 'right' }}
                  />
                </TableCell>
                <TableCell className="custom-cell i-border-r" data-name="vatable">
                  <Dropdown
                    items={vatables}
                    selectedItem={displayTableCells('VATABLE', itinerary)}
                    defaultSelectedItem='NOT VATABLE'
                    onChange={(selectedItem) => updateDataFromDropdown(itinerary.id, "vatable", selectedItem)}
                    otherClasses='w-[85px] h-[21px]'
                  />
                </TableCell>
                <TableCell className="custom-cell i-border-r vat-rate text-right overnight" style={{ textAlign: 'right' }}>
                  {vatRate(itinerary.vatable)}%
                </TableCell>
                <TableCell className="custom-cell i-border-r vendor-cost-net-vat overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Vendor Cost - Net Vat', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r vendor-cost-vat overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Vendor Cost Vat', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r pay-vendor overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('PAY VENDOR', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r guest-rate">
                  <input
                    type="text"
                    value={displayTableCells('GUEST RATE', itinerary)}
                    onKeyDown={preventCharInput}
                    onChange={(e) => handleInputChange(itinerary.id, 'guest_rate', e.target.value, 'RATE - Net Vat', e)}
                    onBlur={() => handleBlur(itinerary.id)}
                    className="border-none outline-none bg-transparent w-full " style={{ textAlign: 'right' }}
                  />
                </TableCell>
                <TableCell className="custom-cell i-border-r guest_cost_net_vat overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Guest Cost - NET VAT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r guest_cost_vat overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Guest Cost VAT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r markup flex justify-center align-items-center" style={{ textAlign: 'right', display: 'flex' }} data-name="markup">
                  <div className='w-fit'>
                    <input
                      type="text"
                      value={displayTableCells('Mark Up', itinerary)}
                      onChange={(e) => handleInputChange(itinerary.id, 'markup', e.target.value, 'Mark Up', e)}
                      className="border-none outline-none bg-transparent w-[1.5rem] text-right"
                    />
                    <span className='pr-1'>%</span>
                  </div>
                </TableCell>
                <TableCell className="custom-cell i-border-r markup-value text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Mark Up VALUE', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r markup-vat text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('Mark Up VAT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r guest-billing-amount text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('GUEST BILLING AMOUNT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r guest-contribution-margin text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('GROSS CONTRIBUTION MARGIN', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r net-contribution-margin text-right" style={{ textAlign: 'right' }}>
                  {displayTableCells('NET CONTRIBUTION MARGIN', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r guest-billing-net-vat text-right" style={{ textAlign: 'right' }}>
                  {displayTableCells('GUEST BILLING - NET VAT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r total-vat text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('TOTAL VAT', itinerary)}
                </TableCell>
                <TableCell className="custom-cell i-border-r price-vat text-right overnight" style={{ textAlign: 'right' }}>
                  {displayTableCells('PRICE VAT', itinerary)}
                </TableCell>
                {/* <TableCell >
                  {displayTableCells('Vendor', itinerary)} 
                </TableCell> */}
                <TableCell className="custom-cell" style={{ borderRight: '0.5px solid #eeefff' }} data-name="customer">
                  <div className="info-section client-section position-relative w-[15rem] h-[21px]">
                    <p className='w-100' onClick={handleShowVendorList}>{itinerary.vendor_name != '' ? itinerary.vendor_name : 'SELECT FROM VENDORS'}</p>
                    <div ref={vendorListRef} className="client-list rounded-radius z-40 right-0 border">
                      <div className='add-new-client' onClick={handleShowVendorForm}>+ ADD A NEW VENDOR</div>
                      <input type="text" name="" id="search-client" placeholder='Search...' onChange={handleVendorSearch} />
                      <ul>
                        {vendors &&
                          vendors.map(vendor => (
                            <li key={vendor.id} data-id={vendor.id} onClick={handleShowSelectedVendor} className={`${itinerary.vendor_id === vendor.id ? 'bg-gray-200' : ''}`}>{vendor.name} - {vendor.email}</li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="client-form-popup client-list rounded-radius bg-white z-40 right-0 border">
                      <span className='client-form-close' onClick={handleHideVendorForm}>X</span>
                      <div className='m-t-3'>
                        <input type="text" placeholder='Full Name' />
                        <br />
                        <input type="email" placeholder='Email' />
                        <br />
                        <input type="button" className='clickable create-client-button' value="CREATE VENDOR" onClick={handleAddNewVendor} />
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll invoice-td`} data-name="invoice" data-id={`invoice-${itinerary.id}`} style={{ minWidth: invoiceWidth }}>
                  <div className="resize-wrapper flex align-items-center h-[18px]">
                    <FileUploader itineraryId={itinerary.id} endpoint='ttp/upload-invoice' updateFile={updateFile} source='invoice' fileUPloaderTdRef={fileUPloaderTdRef} />
                    <FileDisplay itineraryId={itinerary.id} endpoint='invoice-files' deleteEndpoint='invoice-files' />
                    {/* <UploadAttachment
                      itineraryId={123}
                      endpoint={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail/files`}
                      fetchEndpoint={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail`}
                      deleteEndpoint={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/itinerary-detail/files`}
                    /> */}
                  </div>
                </TableCell>
                <TableCell className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll pop-td`} data-name="pop" data-id={`pop-${itinerary.id}`} style={{ minWidth: popWidth }} >
                  <div className="resize-wrapper flex align-items-center h-[18px]">
                    <FileUploader itineraryId={itinerary.id} endpoint='ttp/upload-pop' updateFile={updateFile} source='pop' fileUPloaderTdRef={fileUPloaderTdRef} />
                    <FileDisplay itineraryId={itinerary.id} endpoint='pop-files' deleteEndpoint='pop-files' />
                  </div>
                </TableCell>
                <TableCell className="custom-cell i-border-r" data-name="" contentEditable>

                </TableCell>
              </TableRow>
            ))}
            <TableRow className='sticky bottom-0 z-30 bg-white border-gray-300 table-row'>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border-r-0 border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border-r-0 border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border-r-0 border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border-l-0 border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              {/* <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell> */}
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll px-2' style={{ textAlign: 'right' }}>{totalItineraries?.netContributionMargin ? totalItineraries?.netContributionMargin.toLocaleString('en-US') : '-'}</TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll px-2' style={{ textAlign: 'right' }}>{totalItineraries?.guestCostNetVat && totalItineraries?.markupVat ? (totalItineraries?.guestCostNetVat + totalItineraries?.markupVat).toLocaleString('en-US') : '-'}</TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll overnight'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
              <TableCell className='className={`custom-cell i-border-r pop p-0 border border-gray-300 overflow-auto hide-scroll'></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ItineraryExpand itinerary={expandedItinerary} />
    </div >
  )
}

export default ItineraryDetail