import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Box,
  Typography,
  Collapse,
  Select,
  MenuItem,
  Button
} from '@mui/material';

import FilterList from '@mui/icons-material/FilterList';
import Sort from '@mui/icons-material/Sort';
import ExpandMore from '@mui/icons-material/ExpandMore';
import OpenInFull from '@mui/icons-material/OpenInFull';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LeanConnect from '../Lean/LeanConnect';
import NavigationTabs from '../layouts/NavigationTabs';
import TransactionBreakdownDrawer from './TransactionBreakdownDrawer';

import { RootState } from '../../store';
import { BankConnection, Transaction, SubTransaction, BankAccount, ApiResponse } from '../interfaces';
import { addCommaToWholeNumber } from '../../utilities/helper';
import TransactionCell from './TransactionCell';

import '../../css/lean-style.scss';

/** If you'd like to define a ChartOfAccount interface, do so here: */
interface ChartOfAccount {
  id: number;
  parent_id: number | null;
  account_code: string;
  name: string;
  // ...
}

const ConnectedBanksList: React.FC = () => {
  const { uniCode, bankIdentifier } = useParams();
  const workspaceUniCode = useSelector((state: RootState) => state.workspaceState.selectedWorkspace.uniCode);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankConnections, setBankConnections] = useState<BankConnection[]>([]);
  const [transactions, setTransactions] = useState<Array<Transaction & { sub_transactions?: SubTransaction[] }>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [activeTransaction, setActiveTransaction] = useState<Transaction | null>(null);

  // We'll store leaf accounts for classification
  const [leafAccounts, setLeafAccounts] = useState<ChartOfAccount[]>([]);

  // ------------------ PAGINATION STATES ------------------
  // Adjust pageSize as you like; 50 is common
  // const [currentPage, setCurrentPage] = useState<number>(0);
  // const pageSize = 50;

  const navigate = useNavigate();
  const workspace = localStorage.getItem('workspace');

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchBankConnections(),
          fetchTransactions(),
          fetchLeafAccounts(),
        ]);
      } catch (err) {
        console.error(err);
        // Optionally handle errors here
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [bankIdentifier]);

  // --------------------- Fetch Leaf Accounts ---------------------
  const fetchLeafAccounts = async () => {
    try {
      // Example: Adjust to your actual endpoint for leaf accounts
      const token = localStorage.getItem('token');
      const response = await axios.get<ChartOfAccount[]>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/chart-of-accounts/leaf`,
        {
          params: { workspace },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLeafAccounts(response.data);
    } catch (error) {
      console.error('Error fetching leaf accounts:', error);
    }
  };

  // --------------------- Fetch Bank Connections ---------------------
  const fetchBankConnections = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post<ApiResponse>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections`,
        { workspace },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBankConnections(response.data.bankConnections);
      setBankAccounts(response.data.bankAccounts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank connections:', error);
      setLoading(false);
    }
  };

  // --------------------- Fetch Transactions ---------------------
  const fetchTransactions = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post<{ transactions: Transaction[] }>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions`,
        { workspace, bankIdentifier },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setTransactions(response.data.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  // --------------------- Drawer ---------------------
  const handleOpenDrawer = useCallback((transaction: Transaction) => {
    setActiveTransaction(transaction);
    setDrawerOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setActiveTransaction(null);
    setDrawerOpen(false);
  }, []);

  const handleConfirmSubTransactions = useCallback((subTransactions: SubTransaction[]) => {
    if (activeTransaction) {
      setTransactions((prev) =>
        prev.map((t) => {
          if (t.id === activeTransaction.id) {
            return { ...t, sub_transactions: subTransactions };
          }
          return t;
        })
      );
    }
  }, [activeTransaction]);

  // --------------------- Classification for main transaction ---------------------
  const handleTransactionClassificationChange = async (transactionId: number, chartOfAccountId: number | null) => {
    try {
      const token = localStorage.getItem('token');
      // We'll send sub_transactions: [] if no sub-rows
      const payload = {
        chart_of_account_id: chartOfAccountId,
        sub_transactions: []
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions/${transactionId}/classify`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Merge updated transaction
      const updatedTx: Transaction & { sub_transactions?: SubTransaction[] } = response.data.transaction;
      setTransactions((prev) => prev.map((t) => (t.id === updatedTx.id ? updatedTx : t)));
    } catch (error) {
      console.error('Error classifying transaction:', error);
      alert('Failed to classify transaction.');
    }
  };

  // --------------------- Classification for sub-transaction row ---------------------
  const handleSubRowClassificationChange = async (transactionId: number, index: number, chartOfAccountId: number | null) => {
    // 1. Find the transaction
    const tx = transactions.find((t) => t.id === transactionId);
    if (!tx) return;

    const subRows = tx.sub_transactions || [];
    // 2. Update the sub-row's classification
    const newSubs = [...subRows];
    newSubs[index] = {
      ...newSubs[index],
      chart_of_account_id: chartOfAccountId
    };

    // 3. Build payload: transaction's chart_of_account_id = null if sub-rows exist
    const payload = {
      chart_of_account_id: null,
      sub_transactions: newSubs.map((sr) => ({
        id: sr.id,
        description: sr.description,
        valueDate: sr.value_date,
        amount: sr.amount,
        chart_of_account_id: sr.chart_of_account_id ?? null
      }))
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions/${transactionId}/sub-rows/classify`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      const updatedTx: Transaction & { sub_transactions?: SubTransaction[] } = response.data.transaction;
      // Merge into local state
      setTransactions((prev) => prev.map((t) => (t.id === updatedTx.id ? updatedTx : t)));
    } catch (error) {
      console.error('Error classifying sub-transaction:', error);
      alert('Failed to classify sub-transaction.');
    }
  };

  // ------------------ PAGINATION LOGIC ------------------
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 50; // show 50 rows per page
  const totalPages = Math.ceil(transactions.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = transactions.slice(startIndex, endIndex);

  // --------------------- Tabs ---------------------
  const tabs = useMemo(() => {
    return bankConnections.map((bank) => ({
      label: bank.name.toUpperCase(),
      tabSelector: bank.bank_identifier,
      url: `/banks/${workspaceUniCode}/${bank.bank_identifier.toUpperCase()}`,
      status: "clickable",
    }));
  }, [bankConnections, workspaceUniCode]);

  if (loading) {
    return (
      <>
        <NavigationTabs tabs={tabs} classes='bg-[--dark-gray]' />
        <p className="ml-4">Loading transactions...</p>
      </>
    );
  }

  return (
    <>
      <NavigationTabs tabs={tabs} classes='bg-[--dark-gray]' />
      <LeanConnect />

      {/* Simple Pagination Controls */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px 16px"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 3,
          zIndex: 999,  // ensure it appears on top
        }}
      >
        <Typography variant="body2" mr={2}>
          Page {currentPage + 1} of {Math.max(totalPages, 1)}
        </Typography>

        <Box>
          <Button onClick={handlePrevPage} disabled={currentPage === 0} sx={{ mr: 1 }}>
            Previous
          </Button>
          <Button onClick={handleNextPage} disabled={currentPage >= totalPages - 1}>
            Next
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper} style={{ maxHeight: '100vh', marginTop: '0px', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className="table-header">
              <TableCell padding="checkbox" />
              <TableCell padding="none" />
              <TableCell padding="none" />
              <TableCell>TX REF</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Counterpart</TableCell>
              <TableCell>Classification</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {currentTransactions.map((transaction) => (
              <MemoizedCollapsibleRow
                key={transaction.id}
                transaction={transaction}
                onOpenDrawer={handleOpenDrawer}
                leafAccounts={leafAccounts}
                onTransactionClassificationChange={handleTransactionClassificationChange}
                onSubRowClassificationChange={handleSubRowClassificationChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Single Drawer Instance */}
      <TransactionBreakdownDrawer
        open={drawerOpen}
        transaction={activeTransaction}
        onClose={handleCloseDrawer}
        onConfirm={handleConfirmSubTransactions}
      />
    </>
  );
};

export default ConnectedBanksList;

/** 
 * Collapsible row using <Collapse> for smooth expand/collapse animations.
 * Each row is memoized to avoid re-rendering the entire table.
 */
interface CollapsibleRowProps {
  transaction: Transaction & { sub_transactions?: SubTransaction[] };
  onOpenDrawer: (t: Transaction) => void;

  // Classification props
  leafAccounts: ChartOfAccount[];
  onTransactionClassificationChange: (transactionId: number, chartOfAccountId: number | null) => void;
  onSubRowClassificationChange: (transactionId: number, index: number, chartOfAccountId: number | null) => void;
}

const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  transaction,
  onOpenDrawer,
  leafAccounts,
  onTransactionClassificationChange,
  onSubRowClassificationChange
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const hasSubRows = transaction.sub_transactions && transaction.sub_transactions.length > 0;

  return (
    <>
      {/* Main Row */}
      <TableRow className="table-row">
        <TableCell padding="checkbox">
          <Checkbox />
        </TableCell>
        <TableCell padding="none">
          {(
            <IconButton onClick={toggleExpand}>
              <ExpandMore style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', color: hasSubRows ? '' : 'lightgrey' }} />
            </IconButton>
          )}
        </TableCell>
        <TableCell padding="none">
          <IconButton onClick={() => onOpenDrawer(transaction)}>
            <OpenInFull />
          </IconButton>
        </TableCell>
        <TableCell>
          <TransactionCell transaction={transaction} />
        </TableCell>
        <TableCell>
          {new Date(transaction.value_date_time).toLocaleDateString()}
        </TableCell>
        <TableCell>
          {transaction.transaction_information?.length > 70
            ? transaction.transaction_information.slice(0, 70) + '...'
            : transaction.transaction_information}
        </TableCell>
        <TableCell>
          <span className="currency-badge">{transaction.currency}</span>
        </TableCell>
        <TableCell>
          {transaction.credit_debit_indicator === 'DEBIT'
            ? `-${addCommaToWholeNumber(transaction.amount)}`
            : addCommaToWholeNumber(transaction.amount)}
        </TableCell>
        <TableCell />

        {/* Classification for main transaction (only if NO sub-rows) */}
        <TableCell>
          {hasSubRows ? (
            <Typography variant="body2" color="textSecondary">
              Classify sub-rows below
            </Typography>
          ) : (
            <Select
              value={(transaction.chart_of_account_id as number) ?? ''}
              onChange={(e) => {
                const val = e.target.value ? Number(e.target.value) : null;
                onTransactionClassificationChange(transaction.id, val);
              }}
              displayEmpty
              variant="standard"
              sx={{
                fontSize: '0.8rem',
                lineHeight: 1,
                height: '26px',
                minHeight: '26px',
                '& .MuiSelect-select': {
                  padding: '0 4px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.MuiInputBase-root:before, &.MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
              }}
            >
              <MenuItem value="">
                <em>-- Select Leaf Account --</em>
              </MenuItem>
              {leafAccounts.map((acc) => (
                <MenuItem key={acc.id} value={acc.id}>
                  {acc.account_code} - {acc.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </TableCell>
      </TableRow>

      {/* Sub-rows if expanded */}
      {isExpanded && hasSubRows && transaction.sub_transactions!.map((sr, idx) => (
        <TableRow
          className="table-row"
          key={`${transaction.id}-sub-${idx}`}
          style={{ backgroundColor: '#f9f9f9' }}
        >
          <TableCell padding="checkbox" />
          <TableCell padding="none" />
          <TableCell padding="none" />
          <TableCell>
            {transaction.id}
          </TableCell>
          <TableCell>
            {sr.value_date
              ? new Date(sr.value_date).toLocaleDateString()
              : new Date(transaction.value_date_time).toLocaleDateString()}
          </TableCell>
          <TableCell>
            {sr.description || transaction.transaction_information}
          </TableCell>
          <TableCell>
            <span className="currency-badge">{transaction.currency}</span>
          </TableCell>
          <TableCell>
            {addCommaToWholeNumber(sr.amount)}
          </TableCell>
          <TableCell />

          {/* Classification for sub-row */}
          <TableCell>
            <Select
              value={(sr.chart_of_account_id as number) ?? ''}
              onChange={(e) => {
                const val = e.target.value ? Number(e.target.value) : null;
                onSubRowClassificationChange(transaction.id, idx, val);
              }}
              displayEmpty
              variant="standard"
              sx={{
                fontSize: '0.8rem',
                lineHeight: 1,
                height: '26px',
                minHeight: '26px',
                '& .MuiSelect-select': {
                  padding: '0 4px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.MuiInputBase-root:before, &.MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
              }}
            >
              <MenuItem value="">
                <em>-- Select Leaf Account --</em>
              </MenuItem>
              {leafAccounts.map((acc) => (
                <MenuItem key={acc.id} value={acc.id}>
                  {acc.account_code} - {acc.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const MemoizedCollapsibleRow = React.memo(CollapsibleRow);
