import React from 'react';
import { addCommaToWholeNumber } from '../utilities/helper';
import axios from 'axios';

type BankDetailsPopupProps = {
    bank: any;
    onClose: () => void;
};

const token = localStorage.getItem('token');
const workspace = localStorage.getItem('workspace');

const BankDetailsPopup: React.FC<BankDetailsPopupProps> = ({ bank, onClose }) => {
    console.log(bank);
    const handleRefresh = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/entities/${bank.entity_id}/refresh-balances`,
                {
                    workspace: workspace,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                });

            // Assuming the backend response contains a success message or updated data
            console.log('Balances refreshed successfully:', response.data);
            alert('Balances refreshed successfully!');
        } catch (error) {
            console.error('Error refreshing balances:', error);
            alert('Failed to refresh balances. Please try again.');
        }


    };

    console.log(bank);
    return (
        <div
            className="modal show d-block"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} // Overlay effect
            onClick={onClose}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Bank Details</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            <strong>Bank Name:</strong> {bank.name}
                        </p>
                        <p>
                            <strong>Balance:</strong> {addCommaToWholeNumber(bank.total_balances)}
                            {bank.is_connected ? (
                                <span
                                    className="refresh-icon"
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                    onClick={handleRefresh}
                                    title="Refresh Balance"
                                >
                                    🔄
                                </span>
                            ) : (
                                <span style={{ color: 'red', marginLeft: '10px' }}>Not Connected</span>
                            )}
                        </p>
                        <p>
                            <strong>Incoming:</strong> {addCommaToWholeNumber(bank.incomings)}
                        </p>
                        <p>
                            <strong>Outgoing:</strong> {addCommaToWholeNumber(bank.outgoings)}
                        </p>
                        <p>
                            <strong>Last Updated:</strong> {bank.latest_update}
                        </p>
                        {/* {bank.is_connected ? 'updates......' : 'empty.......'} */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankDetailsPopup;
