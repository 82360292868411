import React from 'react';
import axios from 'axios';
import UpperArrow from '../assets/UpperArrow.svg';
import DownArrow from '../assets/DownArrow.svg';

interface BankType {
    id: number;
    name: string;
    icon_path?: string | null;
    total_balances: number;
    incomings: number;
    outgoings: number;
}

interface BankBalancesPopupProps {
    banks: BankType[];
    onClose: () => void;
}

const token = localStorage.getItem('token');
const workspace = localStorage.getItem('workspace');

const BankBalancesPopup: React.FC<BankBalancesPopupProps> = ({ banks, onClose }) => {
    return (
        <div
            className="modal show d-block"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} // Dark overlay
            onClick={onClose}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                // style={{ maxWidth: '70vw' }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Banks Balances</h5>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        />
                    </div>

                    <div className="modal-body">
                        {/*
              1) width: '100%' ensures the table (and thus each row) spans the full modal width.
              2) tableLayout: 'auto' sizes columns to their content.
            */}
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                borderSpacing: 0,
                                tableLayout: 'auto',
                            }}
                        >
                            <tbody>
                                {banks.map((bank, index) => {
                                    const balanceStr = bank.total_balances.toLocaleString(undefined, { minimumFractionDigits: 2 });
                                    const incomingStr = bank.incomings.toLocaleString(undefined, { minimumFractionDigits: 2 });
                                    const outgoingStr = bank.outgoings.toLocaleString(undefined, { minimumFractionDigits: 2 });

                                    return (
                                        <React.Fragment key={bank.id}>
                                            {/* ---------------- ROW 1 (Labels) ---------------- */}
                                            <tr style={bankRowStyle}>
                                                {/* Cell 1: "BankName Balance" label */}
                                                <td style={tdStyle}>
                                                    <strong>{bank.name} Balance</strong>
                                                </td>

                                                {/* Cell 2: Icon, rowSpan=2 */}
                                                <td style={iconCellStyle} rowSpan={2}>
                                                    {bank.icon_path && (
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_Laravel_API_URL_STRUCTURE +
                                                                'storage/' +
                                                                bank.icon_path
                                                            }
                                                            alt={`${bank.name} icon`}
                                                            style={{
                                                                display: 'block',
                                                                maxHeight: '3em', // limit if needed
                                                                objectFit: 'contain',
                                                            }}
                                                        />
                                                    )}
                                                </td>

                                                {/* Cell 3: "Incoming" label */}
                                                <td style={tdStyle}>
                                                    <strong>Incoming</strong>
                                                </td>

                                                {/* Cell 4: "Outgoing" label */}
                                                <td style={tdStyle}>
                                                    <strong>Outgoing</strong>
                                                </td>
                                            </tr>

                                            {/* ---------------- ROW 2 (Values) ---------------- */}
                                            <tr style={bankRowStyle}>
                                                {/* Cell 1: SR {balance} */}
                                                <td style={tdStyle}>SR {balanceStr}</td>

                                                {/* Cell 3: arrow + SR {incoming} */}
                                                <td style={tdStyle}>
                                                    <div style={arrowValueStyle}>
                                                        <img
                                                            src={UpperArrow}
                                                            alt="upper-arrow"
                                                            className="bg-light-green rounded-radius"
                                                            style={{ marginRight: '4px' }}
                                                        />
                                                        <span>SR {incomingStr}</span>
                                                    </div>
                                                </td>

                                                {/* Cell 4: arrow + SR {outgoing} */}
                                                <td style={tdStyle}>
                                                    <div style={arrowValueStyle}>
                                                        <img
                                                            src={DownArrow}
                                                            alt="down-arrow"
                                                            className="bg-pink rounded-radius"
                                                            style={{ marginRight: '4px' }}
                                                        />
                                                        <span>SR {outgoingStr}</span>
                                                    </div>
                                                </td>
                                            </tr>

                                            {/* Spacer row (white) between different banks */}
                                            {index !== banks.length - 1 && (
                                                <tr>
                                                    <td style={spacerTdStyle} colSpan={4}></td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

/** 
 * The main background for each bank row (both the top & bottom lines).
 * Setting backgroundColor ensures the row extends across the full width
 * because the table is 100% wide.
 */
const bankRowStyle: React.CSSProperties = {
    backgroundColor: '#f8f8f8',
};

/** 
 * Common cell styling. 
 * No borders, minimal padding, 
 * and whiteSpace='nowrap' so columns stay as narrow as possible.
 */
const tdStyle: React.CSSProperties = {
    border: 'none',
    verticalAlign: 'middle',
    padding: '4px 8px',
    whiteSpace: 'nowrap', // Prevents wrapping, keeps column snug
};

/** 
 * Icon cell: center horizontally. 
 * Minimizes padding further if you like.
 */
const iconCellStyle: React.CSSProperties = {
    ...tdStyle,
    textAlign: 'center',
};

/** For the arrow & value to be side by side and centered. */
const arrowValueStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

/** Spacer row: white background, small height, used only between banks. */
const spacerTdStyle: React.CSSProperties = {
    height: '8px',
    backgroundColor: '#fff',
    border: 'none',
    padding: 0,
};

export default BankBalancesPopup;
