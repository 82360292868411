import React, { useState, useEffect } from 'react';

interface DatePickerProps {
  initialDate?: string; // Accept date in `yyyy-mm-dd` format
  name?: string; // Accept date in `yyyy-mm-dd` format
  defaultSelectedDate?: string;
  onDateChange: (backendDate: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ initialDate, defaultSelectedDate = '', onDateChange, name }) => {
  const [displayDate, setDisplayDate] = useState<string>(''); // For `dd/mm/yyyy` format
  const [backendDate, setBackendDate] = useState<string>(defaultSelectedDate); // For `yyyy-mm-dd` format

  useEffect(() => {
    if (initialDate) {
      setBackendDate(initialDate);
      setDisplayDate(formatDateToDDMMYYYY(initialDate));
    } else {
      setBackendDate(''); // Ensure empty date
      setDisplayDate('');
    }
  }, [initialDate]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value; // `yyyy-mm-dd` format
    setBackendDate(selectedDate);
    setDisplayDate(formatDateToDDMMYYYY(selectedDate));
    onDateChange(selectedDate); // Send `yyyy-mm-dd` format to backend
  };

  // Format date from `yyyy-mm-dd` to `dd/mm/yyyy`
  const formatDateToDDMMYYYY = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <input
      type="date"
      name={name}
      value={backendDate}
      onChange={handleDateChange}
      placeholder="mm/dd/yyyy"
      className="border rounded p-2"
    />
  );
};

export default DatePicker;
