import React, { useEffect } from 'react'
import Logout from './auth/Logout'
import { Link } from 'react-router-dom'

const Menu = () => {
  const handleClose = () => {
    const menu = document.getElementById('menu')
    if (menu?.style.left === '0px') {
      menu.style.left = '-16.7%';
    } else {
      if (menu) menu.style.left = '0px';
    }
  }

  // click out side the menu to close it
  window.onclick = function (event) {
    const menu = document.getElementById('menu');
    const logout = document.getElementById('logout')
    const menuIcon = document.getElementById('menu-icon');

    if (event.target != menuIcon && event.target !== menu) {
      if (menu?.style.left === '0px' && logout?.style.left === '0px') {
        menu.style.left = '-16.7%';
        logout.style.left = '-30%';
      }
    }
  }
  return (
    <div id='menu' className=''>
      {/* <span id='close' onClick={handleClose}>X</span> */}
      <ul>
        {/* <Link to="/"><li>Home</li></Link> */}
      </ul>
      <Logout logoutStyle='from-menu logout-button black-border ' />
    </div>
  )
}

export default Menu