import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect, useState } from 'react';
import Country from '../shapes/Country';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import { Vendor } from '../../types/Vendor';
// import { VendorAddress } from '../../types/VendorAddress';
import { Vendor } from '../../types/Vendor';
import { VendorAddress } from '../../types/VendorAddress';

interface Props {
  vendor: Vendor | null;
  vendorAddress: VendorAddress | null;
  handleVendorInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleVendorAddressInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryChange: (newValue: string) => void;
  handleVendorsChange: (vendors: Vendor[]) => void;
}

const VendorPopup: React.FC<Props> = ({ vendor, vendorAddress, handleVendorInputChange, handleVendorAddressInputChange, handleCountryChange, handleVendorsChange }) => {
  const param = useParams();
  const uniCode = param.uniCode;
  const token = localStorage.getItem('token');
  const [vatTreatment, setVatTreatment] = useState<string>('not_registered');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const vendorPopup = document.getElementById('vendor-popup');
      const isReactSelect = (event.target as HTMLElement).closest('.css-1nmdiq5-menu');

      if (vendorPopup) {
        const trIsClicked = (event.target as HTMLElement).parentElement?.classList.contains('vendor-tr');
        const isAddVendor = (event.target as HTMLElement).classList.contains('add-vendor');

        const isChild = vendorPopup.contains(event.target as Node);

        if (!isAddVendor && !isReactSelect && event.target !== vendorPopup && !trIsClicked && !isChild) {
          if (vendorPopup.classList.contains('show')) {
            vendorPopup.classList.remove('show');
          }
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleVatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVatTreatment(e.target.value); // Update state based on selected radio button
  };

  useEffect(() => {
    if (vendor && vendor.vatTreatment) {
      console.log('vendor useEffect:', vendor);
      setVatTreatment(vendor.vatTreatment)
    }
  }, [vendor]);

  const handleRadioCheck = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const target = e.target as HTMLElement;
    const vatTreatment = document.querySelectorAll('.vat-treatment-radio');
    vatTreatment.forEach((treatment) => {
      treatment.removeAttribute('checked');
    });
    const radio = target.previousElementSibling as HTMLInputElement;
    radio.click();
    target.previousElementSibling?.setAttribute('checked', 'checked');
  }

  const addVendor = async (data) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/add-vendor`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data', // Specify content type for form data
        },
      });
      setAlertBody('Vendor added successfully');
      setAlertStatus('success');
      setShowAlert(true);
      console.log(response.data);
      handleVendorsChange(response.data.vendors)
    } catch (error) {
      console.error('Error adding vendor:', error);
      setAlertBody('Vendor was not added');
      setAlertStatus('error');
      setShowAlert(true);
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      const vendorPopup = document.getElementById('vendor-popup') as HTMLElement;
      if (vendorPopup) {
        vendorPopup.classList.remove('show'); // Add the 'show' class to slide in the popup
      }
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    formData.set('vat_treatment', vatTreatment);

    // inputs.forEach((input) => {
    //   formData.append(input.name, input.value);
    // });
    // selects.forEach((select) => {
    //   formData.append(select.name, select.value);
    // });
    if (uniCode) {
      formData.set('uniCode', uniCode);
    }
    console.clear();
    console.log(formData);
    addVendor(formData); // Pass formData variable, not FormData class
  };



  return (
    <div id='vendor-popup' className='extra-popup p-t-3'>
      <h5><b>Contact</b></h5>
      <p className='sub-title'>A person or organization you do business with</p>
      <div className="light-blue_bg highlighted-section">
        Business and VAT Treatment Required
      </div>
      <form id="vendor-form" onSubmit={handleFormSubmit}>
        <input type="hidden" value={uniCode} />
        <input type="hidden" name="vendor_id" value={vendor ? vendor.id : ''} />
        <div className="vendor-form-container">
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Vendor name <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="vendor_name" data-name='name' className='form-control w-100' value={vendor ? vendor.name : ''} required onChange={handleVendorInputChange} />
            </div>
          </div>
          <div className="flex-container country-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Country
              </p>
            </div>
            <div className="flex-2-3">
              {/* <select name="country_id" id="country_id" className='form-control w-100 custom-expand-select'>
                <option value="">Optional</option>
              </select> */}
              <Country
                country={vendorAddress?.country}
                handleCountryChange={handleCountryChange}
              />
            </div>
          </div>
          <div className="vat-treatment-container flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                VAT treatment <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <div className="vat-treatment">
                <input
                  type="radio"
                  name="vat_treatment"
                  className='vat-treatment-radio'
                  value="not_registered"
                  checked={vatTreatment === 'not_registered'}
                  onChange={handleVatChange} required
                />
                <p onClick={handleRadioCheck}>
                  Not VAT registered in KSA - {vatTreatment}
                </p>
              </div>

              <div className="vat-treatment m-t-1">
                <input
                  type="radio"
                  name="vat_treatment"
                  className='vat-treatment-radio'
                  value="registered"
                  checked={vatTreatment === 'registered'}
                  onChange={handleVatChange} required
                />
                <p onClick={handleRadioCheck}>
                  VAT registered in KSA
                </p>
              </div>
            </div>
          </div>
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Tax registration number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="trn" placeholder='Optional' className='form-control w-100' value={vendor ? vendor.trn : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                IBAN
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="iban" placeholder='Optional' className='form-control w-100' value={vendor ? vendor.iban : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                SWIFT
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="swift" placeholder='Optional' className='form-control w-100' value={vendor ? vendor.swift : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
        </div>
        <div className="light-blue_bg highlighted-section m-t-1">
          Address <span className='dark-gray_font'>Optional</span>
        </div>
        <div className="vendor-form-container">
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                City
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="city" className='form-control w-100' placeholder='Optional' value={vendorAddress ? vendorAddress?.city : ''} onChange={handleVendorAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Street address <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="street_address" className='form-control w-100' value={vendorAddress ? vendorAddress?.streetAddress : ''} onChange={handleVendorAddressInputChange} required />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Building number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="building_number" className='form-control w-100' placeholder='Optional' value={vendorAddress ? vendorAddress?.buildingNumber : ''} onChange={handleVendorAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                District
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="district" className='form-control w-100' placeholder='Optional' value={vendorAddress ? vendorAddress?.district : ''} onChange={handleVendorAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Additional number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="additional_number" className='form-control w-100' placeholder='Optional' value={vendorAddress ? vendorAddress?.additionalNumber : ''} onChange={handleVendorAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Postal code
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="postal_code" className='form-control w-100' placeholder='Optional' value={vendorAddress ? vendorAddress?.postalCode : ''} onChange={handleVendorAddressInputChange} />
            </div>
          </div>
        </div>
        <div className="light-blue_bg highlighted-section m-t-1">
          Invoice Info <span className='dark-gray_font'>Optional</span>
        </div>
        <div className="vendor-form-container">
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Code <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="code" className='form-control w-100' value={vendor ? vendor.code : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Email
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="email" placeholder='Optional' className='form-control w-100' value={vendor ? vendor.email : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Phone
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="phone" placeholder='Optional' className='form-control w-100' value={vendor ? vendor.phone : ''} onChange={handleVendorInputChange} />
            </div>
          </div>
        </div>
        <input type="submit" value="Save" className='create-button clickable ' />
      </form>
    </div>
  )
}

export default VendorPopup